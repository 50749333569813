import axios from "axios";
import Config from "./../../config/index";
import { updateUserStatus } from "./auth.service";
import RefreshTokenHandler from "./refresh-token";

/**
 *
 * @param email
 * @param amc_name
 * @param type_of_fund
 * @param date_of_incorporation
 * @param fund_name
 * @param symbol_code
 * @param date_of_revocation
 * @param psx_listing
 */
const addFund = async (
  email: string,
  amc_code: string,
  type_of_fund: string,
  dividend_period: string,
  date_of_incorporation: string,
  fund_name: string,
  symbol_code: string,
  date_of_revocation: string,
  psx_listing: string,
  nav: string,
  money_market: string,
  stock_market: string,
  face_value: string,
  term_deposit: string,
  cdc_participant_id: string,
  cdc_account_number: string,
  offer_price: string,
  redemption_price: string,
  outstanding_units: string,
  management_fee_percentage: string,
  sst_on_management_fee_percentage: string,
  amc_reimbursement_exp_percentage: string,
  selling_and_marketing_exp_percentage: string,
  secp_fee_percentage: string,
  secp_supervisory_fee_percentage: string,
  sst_trustee_percentage: number,
  fee_slab: string,
  sale_load_percentage: number,
  backend_load_percentage: number,
  contingent_load_percentage: number,
  sst_sale_backend_contingent_load_percentage: number
) => {
  const url = `${Config.baseUrl}/fund/`;
  try {
    let result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        type_of_fund,
        dividend_period,
        date_of_incorporation,
        fund_name,
        symbol_code,
        date_of_revocation,
        psx_listing,
        nav,
        money_market,
        stock_market,
        face_value,
        term_deposit,
        cdc_participant_id,
        cdc_account_number,
        offer_price,
        redemption_price,
        outstanding_units,
        management_fee_percentage,
        sst_on_management_fee_percentage,
        amc_reimbursement_exp_percentage,
        selling_and_marketing_exp_percentage,
        secp_fee_percentage,
        secp_supervisory_fee_percentage,
        sst_trustee_percentage,
        fee_slab,
        sale_load_percentage,
        backend_load_percentage,
        contingent_load_percentage,
        sst_sale_backend_contingent_load_percentage,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addFund(
          email,
          amc_code,
          type_of_fund,
          dividend_period,
          date_of_incorporation,
          fund_name,
          symbol_code,
          date_of_revocation,
          psx_listing,
          nav,
          money_market,
          stock_market,
          face_value,
          term_deposit,
          cdc_participant_id,
          cdc_account_number,
          offer_price,
          redemption_price,
          outstanding_units,
          management_fee_percentage,
          sst_on_management_fee_percentage,
          amc_reimbursement_exp_percentage,
          selling_and_marketing_exp_percentage,
          secp_fee_percentage,
          secp_supervisory_fee_percentage,
          sst_trustee_percentage,
          fee_slab,
          sale_load_percentage,
          backend_load_percentage,
          contingent_load_percentage,
          sst_sale_backend_contingent_load_percentage
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

// Add Daily Nav
// /**
//  *
//  * @param email
//  * @param amc_name
//  * @param fund
//  * @param nav
//  * @param nav_date
//  * @param symbol_code
//  */
//  const addDailyNav = async (
//   email: string,
//   amc_code: string,
//   fund: string,
//   nav: string,
//   nav_date: string,
//   total_fund_units: string,
//   total_net_asset: string,
//   // offer_rate: string,
// ) => {
//   const url = `${Config.baseUrl}/fund/daily-nav`;
//   const result: any = await axios.post(
//     url,
//     {
//       email,
//       amc_code,
//       fund,
//       nav,
//       nav_date,
//       total_fund_units,
//       total_net_asset,
//       offer_rate: 'sale',
//     },
//     {
//       headers: {
//         Authorization: sessionStorage.getItem('token') || '',
//       },
//     }
//   );
//   return result;
// };

const addDailyNav = async (
  email: string,
  data: string
  // offer_rate: string,
) => {
  const url = `${Config.baseUrl}/fund/bulk-daily-nav`;
  try {
    let result: any = await axios.post(
      url,
      {
        email,
        data,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addDailyNav(email, data);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/***
 * @param email
 */
const getDailyNavByDate = async (
  email: string,
  fund: string,
  nav_date: string,
  amc_code: string
) => {
  const url = `${Config.baseUrl}/fund/get-daily-nav?email=${email}&fund=${fund}&nav_date=${nav_date}&amc_code=${amc_code}`;
  try {
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDailyNavByDate(email, fund, nav_date, amc_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/***
 * @param email
 */
const getDailyNavOfAllFunds = async (
  email: string,
  amc_code: string,
  nav_date: string
) => {
  const url = `${Config.baseUrl}/fund/get-daily-nav-by-amc-code?email=${email}&amc_code=${amc_code}&nav_date=${nav_date}`;
  try {
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getDailyNavOfAllFunds(email, amc_code, nav_date);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 * @param email
 */
const getFunds = async (
  email: string,
  page_number: string = "",
  page_size: string = "",
  fund_code: string = "",
  amc_code: string
) => {
  const url = `${Config.baseUrl}/fund?email=${email}&page_number=${page_number}&page_size=${page_size}&fund_code=${fund_code}&amc_code=${amc_code}`;
  try {
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getFunds(
          email,
          page_number,
          page_size,
          fund_code,
          amc_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
/**
 * @param email
 */
const getFundByAmc = async (
  email: string,
  amcCode: string,
  fund_code: string = ""
) => {
  const url = `${Config.baseUrl}/fund/by-amc-code?email=${email}&amc_code=${amcCode}&fund_code=${fund_code}`;
  try {
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getFundByAmc(email, amcCode, fund_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
const EditFund = async (
  email: string,
  amc_code: string,
  type_of_fund: string,
  dividend_period: string,
  date_of_incorporation: string,
  fund_name: string,
  symbol_code: string,
  date_of_revocation: string,
  psx_listing: string,
  nav: string,
  money_market: string,
  stock_market: string,
  face_value: string,
  term_deposit: string,
  cdc_participant_id: string,
  cdc_account_number: string,
  offer_price: string,
  redemption_price: string,
  outstanding_units: string,
  management_fee_percentage: string,
  sst_on_management_fee_percentage: string,
  amc_reimbursement_exp_percentage: string,
  selling_and_marketing_exp_percentage: string,
  secp_fee_percentage: string,
  secp_supervisory_fee_percentage: string,
  sst_trustee_percentage: number,
  fee_slab: string,
  sale_load_percentage: number,
  backend_load_percentage: number,
  contingent_load_percentage: number,
  sst_sale_backend_contingent_load_percentage: number
) => {
  const url = `${Config.baseUrl}/fund/update`;
  try {
    let result: any = await axios.post(
      url,
      {
        email,
        amc_code,
        type_of_fund,
        dividend_period,
        date_of_incorporation,
        fund_name,
        symbol_code,
        date_of_revocation,
        psx_listing,
        nav,
        money_market,
        stock_market,
        face_value,
        term_deposit,
        cdc_participant_id,
        cdc_account_number,
        offer_price,
        redemption_price,
        outstanding_units,
        management_fee_percentage,
        sst_on_management_fee_percentage,
        amc_reimbursement_exp_percentage,
        selling_and_marketing_exp_percentage,
        secp_fee_percentage,
        secp_supervisory_fee_percentage,
        sst_trustee_percentage,
        fee_slab,
        sale_load_percentage,
        backend_load_percentage,
        contingent_load_percentage,
        sst_sale_backend_contingent_load_percentage,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await EditFund(
          email,
          amc_code,
          type_of_fund,
          dividend_period,
          date_of_incorporation,
          fund_name,
          symbol_code,
          date_of_revocation,
          psx_listing,
          nav,
          money_market,
          stock_market,
          face_value,
          term_deposit,
          cdc_participant_id,
          cdc_account_number,
          offer_price,
          redemption_price,
          outstanding_units,
          management_fee_percentage,
          sst_on_management_fee_percentage,
          amc_reimbursement_exp_percentage,
          selling_and_marketing_exp_percentage,
          secp_fee_percentage,
          secp_supervisory_fee_percentage,
          sst_trustee_percentage,
          fee_slab,
          sale_load_percentage,
          backend_load_percentage,
          contingent_load_percentage,
          sst_sale_backend_contingent_load_percentage
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
const updateFundStatus = async (
  email: string,
  symbol_code: string,
  status: string,
  amc_code: string
) => {
  const url = `${Config.baseUrl}/fund/update`;
  try {
    let result: any = await axios.post(
      url,
      { email, symbol_code, status, amc_code },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateFundStatus(email, symbol_code, status, amc_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 *
 * @param search_value
 */
const searchFundAPI = async (
  email: string,
  search_value: string,
  fund_code: string = "",
  amc_code: string = ""
) => {
  const url = `${Config.baseUrl}/fund?email=${email}&search_value=${search_value}&fund_code=${fund_code}&amc_code=${amc_code}`;
  try {
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await searchFundAPI(email, search_value, fund_code, amc_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 * @param symbol_code
 */
const deleteFund = async (email: string, symbol_code: string) => {
  const url = `${Config.baseUrl}/fund/delete-fund/`;
  try {
    let result: any = await axios.put(
      url,
      { email, symbol_code },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await deleteFund(email, symbol_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const EditNavFund = async (obj: string) => {
  const url = `${Config.baseUrl}/fund/update`;
  try {
    let result: any = await axios.post(url, obj, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await EditNavFund(obj);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 */
const getFundType = async (email: string) => {
  try {
    const url = `${Config.baseUrl}/mastertxn/mop/?email=${email}`;
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getFundType(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addModeOfPayment = async (email: string, name: string, code: string) => {
  const url = `${Config.baseUrl}/mastertxn/mop/`;
  try {
    let result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addModeOfPayment(email, name, code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addNatureOfTransaction = async (
  email: string,
  name: string,
  code: string
) => {
  const url = `${Config.baseUrl}/mastertxn/not/`;
  try {
    let result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addNatureOfTransaction(email, name, code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addModeOfTransaction = async (
  email: string,
  name: string,
  code: string
) => {
  const url = `${Config.baseUrl}/mastertxn/tot/`;
  try {
    let result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addModeOfTransaction(email, name, code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addInstrumentType = async (email: string, name: string, code: string) => {
  const url = `${Config.baseUrl}/mastertxn/it/`;
  try {
    let result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addInstrumentType(email, name, code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
/**
 *
 * @param email
 */
const getInstrumentType = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/it?email=${email}`;
  try {
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getInstrumentType(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const UpdateNavOfAllFunds = async (email: string, funds: string) => {
  const url = `${Config.baseUrl}/fund/update-multiple`;
  try {
    let result: any = await axios.post(
      url,
      { email, funds },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await UpdateNavOfAllFunds(email, funds);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export {
  addFund,
  UpdateNavOfAllFunds,
  EditNavFund,
  EditFund,
  updateFundStatus,
  getFundByAmc,
  getFunds,
  getInstrumentType,
  deleteFund,
  searchFundAPI,
  getFundType,
  addModeOfPayment,
  addNatureOfTransaction,
  addModeOfTransaction,
  addInstrumentType,
  addDailyNav,
  getDailyNavByDate,
  getDailyNavOfAllFunds,
};
