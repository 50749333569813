import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { getFundByAmc } from "../../../stores/services/funds.service";
import {
  getChartOfAccounts,
  getTransactionsLedgers,
} from "../../../stores/services/chatofaccounts.service";
import {
  getAllLedgers,
  getLedgerAccounts,
  getLedgersByTxn,
} from "../../../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../../../stores/services/daily-expense.service";
import { getLedgerInfoByCode } from "../../../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../../../stores/services/beta-transaction.service";
import { numberWithCommas } from "../../../utils/customFunction";
import { getPortfolioByDate } from "../../../stores/services/daily-unrealized-portfolio.service";
import { Modal } from "react-bootstrap";
import {
  getAllDividendAnnouncements,
  postToDividendAnnouncement,
  updateDividendAnnouncement,
  changeActiveStatus,
  changePaidStatus,
} from "../../../stores/services/dividend-announcement.service";
import NumberFormat from "react-number-format";

const DividendAnnouncement = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundCheck, setFundCheck] = useState<any>(false);
  const [fund, setFund] = useState("");
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [tableData, setTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);

  let today: any = moment().format("YYYY-MM-DD");
  // let dd = String(today.getDate()).padStart(2, "0");
  // let mm = String(today.getMonth() + 1).padStart(2, "0");
  // let yyyy = today.getFullYear();

  // today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState<any>(today);

  const [disabled, setDisabled] = useState<any>(false);

  const [announcementDescription, setAnnouncementDescription] =
    useState<any>("");
  const [redemptionDate, setRedemptionDate] = useState<any>(today);
  const [openingExNav, setOpeningExNav] = useState<any>(0.0);
  const [closingNavBeforeDistribution, setClosingNavBeforeDistribution] =
    useState<any>(0.0);
  const [payoutRatio, setPayoutRatio] = useState<any>(0.0);
  const [dividendPerUnit, setDividendPerUnit] = useState<any>(0.0);
  const [exNavAfterDistribution, setExNavAfterDistribution] =
    useState<any>(0.0);
  const [modalLoading, setModalLoading] = useState<any>(false);
  const [fromDate, setFromDate] = useState<any>(today);
  const [toDate, setToDate] = useState<any>(today);

  const [viewId, setViewId] = useState<any>("");
  let editId: any = "";
  let statusId: any = "";
  let paidId: any = "";
  const [editLoading, setEditLoading] = useState<any>(true);
  const [systemDate, setSystemDate] = useState<any>("");
  const [announementId, setAnnouncementId] = useState<any>("");
  let compareData: any = "";

  const handleCalculation = (e, type) => {
    let closing: any = closingNavBeforeDistribution;
    let opening: any = openingExNav;
    let payout: any = payoutRatio;
    let calculation: any = 0;

    if (type === "opening") {
      closing = closingNavBeforeDistribution;
      opening = e.target.value?.replaceAll(",", "");
      payout = payoutRatio;
      calculation = (closing - opening) * (payout / 100);
    }
    if (type === "closing") {
      closing = e.target.value?.replaceAll(",", "");
      opening = openingExNav;
      payout = payoutRatio;
      calculation = (closing - opening) * (payout / 100);

      setExNavAfterDistribution((+closing || 0) - (+calculation || 0));
    }
    if (type === "payout") {
      closing = closingNavBeforeDistribution;
      opening = openingExNav;
      payout = e.target.value?.replaceAll(",", "");
      calculation = (closing - opening) * (payout / 100);
    }

    setDividendPerUnit(calculation);
    //Dividend Per Unit = (closingNavBeforeDistribution - Opening Ex Nav) * (payoutRatio / 100)
  };

  const handleAdd = async () => {
    setModalLoading(true);

    if (!amc_code) {
      setModalLoading(false);

      return toast.error("AMC not found. Login Again!");
    }
    if (!fund) {
      setModalLoading(false);
      return toast.error("Fund is required!");
    }
    if (!date) {
      setModalLoading(false);
      return toast.error("Problem getting system date. Retry!");
    }
    if (!announcementDescription || announcementDescription === "") {
      setModalLoading(false);
      return toast.error("Description is required!");
    }
    if (!redemptionDate || redemptionDate === today) {
      setModalLoading(false);
      return toast.error("Redemption date is required");
    }
    if (!openingExNav || openingExNav === 0.0) {
      setModalLoading(false);
      return toast.error("Opening Ex Nav is required");
    }
    if (!closingNavBeforeDistribution || closingNavBeforeDistribution === 0.0) {
      setModalLoading(false);
      return toast.error("Closing Nav before distribution is required");
    }
    if (!payoutRatio || payoutRatio === 0.0) {
      setModalLoading(false);
      return toast.error("Payout Ratio is required!");
    }
    if (!dividendPerUnit || dividendPerUnit === 0.0) {
      setModalLoading(false);
      return toast.error("Dividend per unit is required");
    }
    if (!exNavAfterDistribution || exNavAfterDistribution === 0.0) {
      setModalLoading(false);
      return toast.error("Ex Nav after distribution is required");
    }

    const result = await postToDividendAnnouncement(
      email,
      amc_code,
      fund,
      announcementDescription,
      date,
      redemptionDate,
      openingExNav,
      closingNavBeforeDistribution,
      payoutRatio,
      dividendPerUnit,
      exNavAfterDistribution,
      fromDate,
      toDate
    );

    if (result.data.status === 200) {
      toast.success("Record added!");
    }

    setTimeout(() => {
      getDividendAnnouncements();
    }, 3000);
    setModalLoading(false);
    setFund("");
    setAnnouncementDescription("");
    setRedemptionDate(today);
    setFromDate(today);
    setToDate(today);
    setOpeningExNav(0.0);
    setClosingNavBeforeDistribution(0.0);
    setPayoutRatio(0.0);
    setDividendPerUnit(0.0);
    setDividendPerUnit(0.0);
    setExNavAfterDistribution(0.0);
    setViewAddModal(false);
  };

  const handleStatusChange = async () => {
    setLoading(true);
    let announcement_id = statusId;
    let status = "active";
    let statusData = tableData.find((data, index) => {
      return data.announcement_id === announcement_id;
    });
    if (statusData.status === "active") {
      status = "inactive";
    } else if (statusData.status === "inactive") {
      status = "active";
    }
    const response = await changeActiveStatus(email, announcement_id, status);
    setTimeout(() => {
      getDividendAnnouncements();
    }, 3000);
  };

  const handlePaidChange = async () => {
    setLoading(true);
    let announcement_id = paidId;
    let paid = "false";
    let paidData = tableData.find((data, index) => {
      return data.announcement_id === announcement_id;
    });
    if (paidData?.paid === "true") {
      paid = "false";
    } else if (paidData?.paid === "false") {
      paid = "true";
    }
    const response = await changePaidStatus(email, announcement_id, paid);
    setTimeout(() => {
      getDividendAnnouncements();
    }, 3000);
  };

  const handleEdit = async () => {
    try {
      setModalLoading(true);

      if (!amc_code) {
        setModalLoading(false);
        return toast.error("AMC not found. Login Again!");
      }
      if (!fund) {
        setModalLoading(false);
        return toast.error("Fund is required!");
      }
      if (!systemDate) {
        setModalLoading(false);
        return toast.error("Problem getting system date. Retry!");
      }
      if (!announcementDescription || announcementDescription === "") {
        setModalLoading(false);
        return toast.error("Description is required!");
      }
      if (!redemptionDate) {
        setModalLoading(false);
        return toast.error("Redemption date is required");
      }
      if (!openingExNav || openingExNav === 0.0) {
        setModalLoading(false);
        return toast.error("Opening Ex Nav is required");
      }
      if (
        !closingNavBeforeDistribution ||
        closingNavBeforeDistribution === 0.0
      ) {
        setModalLoading(false);
        return toast.error("Closing Nav before distribution is required");
      }
      if (!payoutRatio || payoutRatio === 0.0) {
        setModalLoading(false);
        return toast.error("Payout Ratio is required!");
      }
      if (!dividendPerUnit || dividendPerUnit === 0.0) {
        setModalLoading(false);
        return toast.error("Dividend per unit is required");
      }
      if (!exNavAfterDistribution || exNavAfterDistribution === 0.0) {
        setModalLoading(false);
        return toast.error("Ex Nav after distribution is required");
      }

      const result = await updateDividendAnnouncement(
        email,
        announementId,
        amc_code,
        fund,
        announcementDescription,
        date,
        redemptionDate,
        openingExNav,
        closingNavBeforeDistribution,
        payoutRatio?.toString() || "0.0",
        dividendPerUnit,
        exNavAfterDistribution,
        fromDate,
        toDate
      );

      if (result.data.status == 200 || result.data.staus == 200) {
        toast.success(result.data.message);
        setTimeout(() => {
          getDividendAnnouncements();
        }, 3000);
        setModalLoading(false);
        setFund("");
        setAnnouncementDescription("");
        setRedemptionDate(today);
        setFromDate(today);
        setToDate(today);
        setOpeningExNav(0.0);
        setClosingNavBeforeDistribution(0.0);
        setPayoutRatio(0.0);
        setDividendPerUnit(0.0);
        setExNavAfterDistribution(0.0);
        setViewEditModal(false);
      } else {
        setModalLoading(false);
        toast.error(result.data.message);
      }
    } catch (error: any) {
      console.log("Error", error);
      setModalLoading(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message?.toString());
      } else {
        toast.error(error.response.data.message?.toString());
      }
    }
  };
  //add modal
  const [viewAddModal, setViewAddModal] = useState(false);
  const renderModalForAdd = () => {
    if (viewAddModal) {
      return (
        <Modal
          className=""
          dialogClassName="announcementModal"
          show={true}
          size="xl"
          backdrop={true}
          onHide={() => {
            setModalLoading(false);
            setFund("");
            setAnnouncementDescription("");
            setRedemptionDate(today);
            setFromDate(today);
            setToDate(today);
            setOpeningExNav(0.0);
            setClosingNavBeforeDistribution(0.0);
            setPayoutRatio(0.0);
            setDividendPerUnit(0.0);

            setExNavAfterDistribution(0.0);

            setViewAddModal(false);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setModalLoading(false);
                setFund("");
                setAnnouncementDescription("");
                setRedemptionDate(today);
                setFromDate(today);
                setToDate(today);
                setOpeningExNav(0.0);
                setClosingNavBeforeDistribution(0.0);
                setPayoutRatio(0.0);
                setDividendPerUnit(0.0);

                setExNavAfterDistribution(0.0);

                setViewAddModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Add Dividend Announcement</h6>
          </div>

          <div className="modal-body">
            <Row>
              <div className="form-group col-md-6" data-tip="Select Fund">
                Fund Name*
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                {accFundLoading ? (
                  <div className="form-control w-100">
                    <i className="fa fa-spinner fa-spin fa-1x"></i>
                  </div>
                ) : (
                  <select
                    className={"form-control w-100 "}
                    value={fund}
                    disabled={false}
                    onChange={(e) => {
                      setFund(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      {fundCheck ? "No Fund Found" : "Select Fund"}
                    </option>
                    {renderFundsDropdown()}
                  </select>
                )}
              </div>
              <div className="form-group col-md-6">
                <div>Opening Ex Nav</div>
                <input
                  type="number"
                  placeholder="Opening Ex Nav"
                  value={numberWithCommas(openingExNav)}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    setOpeningExNav(e.target.value?.replaceAll(",", ""));
                    handleCalculation(e, "opening");
                  }}
                />
              </div>
              <div
                className="form-group col-md-6"
                data-tip="Describe the announcement"
              >
                <div>Description*</div>
                <input
                  type="text"
                  placeholder="Announcement Description"
                  value={announcementDescription}
                  className={"form-control w-100"}
                  onChange={(e) => setAnnouncementDescription(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <div>Closing Nav before distribution</div>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  style={{ textAlign: "right" }}
                  placeholder="Closing Nav before distribution"
                  value={closingNavBeforeDistribution}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    setClosingNavBeforeDistribution(
                      e.target.value?.replaceAll(",", "")
                    );
                    handleCalculation(e, "closing");
                  }}
                />
              </div>
              <div className="form-group col-md-6" data-tip="Today's Date">
                <div>System Date</div>
                <input
                  type="date"
                  value={date}
                  defaultValue={date}
                  className={"form-control w-100 "}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <div>Payout Ratio %</div>
                <input
                  type="number"
                  placeholder="Payout Ratio %"
                  value={payoutRatio}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    if (e.target.value.length > 5) {
                      return;
                    }
                    if (parseFloat(e.target.value) > 100.0) {
                      return;
                    }
                    if (parseFloat(e.target.value) < 0.0) {
                      return;
                    }
                    setPayoutRatio(e.target.value);
                    handleCalculation(e, "payout");
                  }}
                />
              </div>
              <div
                className="form-group col-md-6"
                data-tip="Select Redemption Date"
              >
                Redemption Date
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <input
                  className="form-control"
                  type="date"
                  value={redemptionDate}
                  defaultValue={redemptionDate}
                  onChange={(e) => {
                    setRedemptionDate(e.target.value);
                  }}
                />
              </div>

              <div className="form-group col-md-6">
                <div>Dividend Per Unit</div>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  decimalScale={4}
                  style={{ textAlign: "right" }}
                  placeholder="Dividend Per Unit"
                  value={dividendPerUnit || "0"}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    setDividendPerUnit(e.target.value?.replaceAll(",", ""));
                    setExNavAfterDistribution(
                      (+closingNavBeforeDistribution || 0) -
                        (+e.target.value?.replaceAll(",", "") || 0)
                    );
                  }}
                />
              </div>

              <div
                className="form-group col-md-6"
                data-tip="Select Period Starting Date"
              >
                Dividend From
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <input
                  className="form-control"
                  type="date"
                  value={fromDate}
                  defaultValue={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />
              </div>

              <div className="form-group col-md-6">
                <div>Ex Nav after distribution</div>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  style={{ textAlign: "right" }}
                  placeholder="Ex Nav after distribution"
                  value={exNavAfterDistribution}
                  className={"form-control w-100"}
                  onChange={(e) =>
                    setExNavAfterDistribution(
                      e.target.value?.replaceAll(",", "")
                    )
                  }
                />
              </div>

              <div
                className="form-group col-md-6"
                data-tip="Select Period Ending Date"
              >
                Dividend To
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <input
                  className="form-control"
                  type="date"
                  value={toDate}
                  defaultValue={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                />
              </div>
            </Row>
            <Row>
              <Col>
                <div className="form-group d-flex justify-content-between">
                  <div className="hidden"></div>
                  <button
                    className="btn"
                    onClick={() => {
                      handleAdd();
                    }}
                    disabled={
                      modalLoading ||
                      !fund ||
                      !amc_code ||
                      !announcementDescription
                    }
                  >
                    Add Announcement
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  };

  //view modal
  const [viewViewModal, setViewViewModal] = useState(false);
  const renderModalForView = (index) => {
    if (viewViewModal === true) {
      let viewData = tableData.find((data, index) => {
        return data.announcement_id === viewId;
      });

      return (
        <Modal
          className=""
          dialogClassName="announcementModal"
          show={true}
          size="xl"
          backdrop={true}
          onHide={() => {
            setViewViewModal(false);
            setFund("");
            setAnnouncementDescription("");
            setRedemptionDate(today);
            setFromDate(today);
            setToDate(today);
            setOpeningExNav(0.0);
            setClosingNavBeforeDistribution(0.0);
            setPayoutRatio(0.0);
            setDividendPerUnit(0.0);
            setExNavAfterDistribution(0.0);
            setSystemDate(today);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewViewModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">View Dividend Announcement</h6>
          </div>

          <div className="modal-body">
            <Row>
              <div className="form-group col-md-6" data-tip="Select Fund">
                Fund Name*
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                {accFundLoading ? (
                  <div className="form-control w-100">
                    <i className="fa fa-spinner fa-spin fa-1x"></i>
                  </div>
                ) : (
                  <select className={"form-control w-100 "} disabled>
                    <option value={viewData.fund_code} defaultChecked hidden>
                      {viewData.fund_code}
                    </option>
                  </select>
                )}
              </div>
              <div className="form-group col-md-6">
                <div>Opening Ex Nav</div>
                <input
                  type="number"
                  placeholder="Opening Ex Nav"
                  value={numberWithCommas(viewData.opening_ex_nav)}
                  className={"form-control w-100"}
                  disabled
                />
              </div>

              <div
                className="form-group col-md-6"
                data-tip="Describe the announcement"
              >
                <div>Description*</div>
                <input
                  type="text"
                  placeholder="Announcement Description"
                  value={viewData.description}
                  className={"form-control w-100"}
                  disabled
                />
              </div>

              <div className="form-group col-md-6">
                <div>Closing Nav before distribution</div>
                <input
                  type="number"
                  placeholder="Closing Nav before distribution"
                  value={numberWithCommas(viewData.closing_nav_before_dist)}
                  className={"form-control w-100"}
                  disabled
                />
              </div>

              <div className="form-group col-md-6" data-tip="Creation Date">
                <div>System Date</div>
                <input
                  type="date"
                  value={
                    viewData.created_at
                      ? moment(viewData.created_at).format("YYYY-MM-DD")
                      : ""
                  }
                  className={"form-control w-100 "}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <div>Payout Ratio %</div>
                <input
                  type="number"
                  placeholder="Payout Ratio %"
                  value={viewData.payout_ratio_percent}
                  className={"form-control w-100"}
                  disabled
                />
              </div>

              <div
                className="form-group col-md-6"
                data-tip="Select Redemption Date"
              >
                Redemption Date
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <input
                  className="form-control"
                  type="date"
                  value={
                    viewData.redemption_date
                      ? moment(viewData.redemption_date).format("YYYY-MM-DD")
                      : ""
                  }
                  disabled
                />
              </div>

              <div className="form-group col-md-6">
                <div>Dividend Per Unit</div>
                <input
                  type="number"
                  placeholder="Dividend Per Unit"
                  className={"form-control w-100"}
                  value={numberWithCommas(viewData.dividend_per_unit)}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <div>Ex Nav after distribution</div>
                <input
                  type="number"
                  placeholder="Ex Nav after distribution"
                  value={numberWithCommas(viewData.ex_nav_after_dist)}
                  className={"form-control w-100"}
                  disabled
                />
              </div>
            </Row>
            <Row>
              <Col>
                <div className="form-group d-flex justify-content-between">
                  <div className="hidden"></div>
                  <button
                    className="btn"
                    onClick={() => {
                      setViewViewModal(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  };
  //edit modal
  const [viewEditModal, setViewEditModal] = useState(false);
  const renderModalForEdit = (index) => {
    if (viewEditModal === true) {
      return (
        <Modal
          className=""
          dialogClassName="announcementModal"
          show={true}
          size="xl"
          backdrop={true}
          onHide={() => {
            setViewEditModal(false);
            setFund("");
            setAnnouncementDescription("");
            setRedemptionDate(today);
            setFromDate(today);
            setToDate(today);
            setOpeningExNav(0.0);
            setClosingNavBeforeDistribution(0.0);
            setPayoutRatio(0.0);
            setDividendPerUnit(0.0);
            setExNavAfterDistribution(0.0);
            setSystemDate(today);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewEditModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Edit Dividend Announcement</h6>
          </div>

          <div className="modal-body">
            <Row>
              <div className="form-group col-md-6" data-tip="Select Fund">
                Fund Name*
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                {accFundLoading ? (
                  <div className="form-control w-100">
                    <i className="fa fa-spinner fa-spin fa-1x"></i>
                  </div>
                ) : (
                  <select
                    className={"form-control w-100 "}
                    value={fund}
                    disabled={false}
                    onChange={(e) => {
                      setFund(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      {fundCheck ? "No Fund Found" : "Select Fund"}
                    </option>
                    {renderFundsDropdown()}
                  </select>
                )}
              </div>
              <div className="form-group col-md-6">
                <div>Opening Ex Nav</div>
                <input
                  type="number"
                  placeholder="Opening Ex Nav"
                  value={numberWithCommas(openingExNav)}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    setOpeningExNav(e.target.value?.replaceAll(",", ""));
                    handleCalculation(e, "opening");
                  }}
                />
              </div>

              <div
                className="form-group col-md-6"
                data-tip="Describe the announcement"
              >
                <div>Description*</div>
                <input
                  type="text"
                  placeholder="Announcement Description"
                  value={announcementDescription}
                  className={"form-control w-100"}
                  onChange={(e) => setAnnouncementDescription(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <div>Closing Nav before distribution</div>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  style={{ textAlign: "right" }}
                  placeholder="Closing Nav before distribution"
                  value={closingNavBeforeDistribution}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    setClosingNavBeforeDistribution(
                      e.target.value?.replaceAll(",", "")
                    );
                    handleCalculation(e, "closing");
                  }}
                />
              </div>

              <div className="form-group col-md-6" data-tip="Today's Date">
                <div>System Date</div>
                <input
                  type="date"
                  value={systemDate}
                  defaultValue={systemDate}
                  className={"form-control w-100 "}
                  disabled
                />
              </div>
              <div className="form-group col-md-6">
                <div>Payout Ratio %</div>
                <input
                  type="number"
                  placeholder="Payout Ratio %"
                  value={payoutRatio}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    if (e.target.value.length > 5) {
                      return;
                    }
                    if (parseFloat(e.target.value) > 100.0) {
                      return;
                    }
                    if (parseFloat(e.target.value) < 0.0) {
                      return;
                    }
                    setPayoutRatio(e.target.value);
                    handleCalculation(e, "payout");
                  }}
                />
              </div>
              <div
                className="form-group col-md-6"
                data-tip="Select Redemption Date"
              >
                Redemption Date
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <input
                  className="form-control"
                  type="date"
                  value={redemptionDate}
                  defaultValue={redemptionDate}
                  onChange={(e) => {
                    setRedemptionDate(e.target.value);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <div>Dividend Per Unit</div>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  style={{ textAlign: "right" }}
                  decimalScale={4}
                  placeholder="Dividend Per Unit"
                  value={numberWithCommas(dividendPerUnit)}
                  className={"form-control w-100"}
                  onChange={(e) => {
                    setDividendPerUnit(e.target.value?.replaceAll(",", ""));
                    setExNavAfterDistribution(
                      (+closingNavBeforeDistribution || 0) -
                        (+e.target.value?.replaceAll(",", "") || 0)
                    );
                  }}
                />
              </div>

              <div
                className="form-group col-md-6"
                data-tip="Select Period Starting Date"
              >
                Dividend From
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <input
                  className="form-control"
                  type="date"
                  value={fromDate}
                  defaultValue={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />
              </div>

              <div className="form-group col-md-6">
                <div>Ex Nav after distribution</div>
                <NumberFormat
                  thousandSeparator={true}
                  inputMode="numeric"
                  style={{ textAlign: "right" }}
                  placeholder="Ex Nav after distribution"
                  value={exNavAfterDistribution}
                  className={"form-control w-100"}
                  onChange={(e) =>
                    setExNavAfterDistribution(
                      e.target.value?.replaceAll(",", "")
                    )
                  }
                />
              </div>

              <div
                className="form-group col-md-6"
                data-tip="Select Period Ending Date"
              >
                Dividend To
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <input
                  className="form-control"
                  type="date"
                  value={toDate}
                  defaultValue={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                />
              </div>
            </Row>
            <Row>
              <Col>
                <div className="form-group d-flex justify-content-between">
                  <div className="hidden"></div>
                  <button
                    className="btn"
                    onClick={() => {
                      handleEdit();
                    }}
                    disabled={
                      modalLoading ||
                      !fund ||
                      !amc_code ||
                      !announcementDescription
                    }
                  >
                    Edit Announcement
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  };

  useEffect(() => {
    getDividendAnnouncements();
  }, []);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          code,
          fund_code?.length > 0 ? fund_code : ""
        );
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {}
    };

    getFundByAMcCode(amc_code);
  }, []);

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const getDividendAnnouncements = async () => {
    setLoading(true);
    const result = await getAllDividendAnnouncements(email, amc_code);
    if (result.data.status === 404) {
      toast.error("No record found!");
    }
    setTableData([...result?.data?.data]);
    setLoading(false);
  };

  return (
    <>
      {/* <ToastContainer limit={1} /> */}
      <ToastContainer />

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        //history.replace("/admin/dashboard");
                        history.goBack();
                      }}
                    ></i>
                    Dividend Announcements
                  </h4>
                </div>
              </div>

              {
                Loading ? (
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-center my-5">
                        <i
                          className="fa fa-spinner fa-spin fa-3x"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  //   tableData.length !== 0 ? (
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div>
                            <h4 className="card-title text-primary">
                              Dividend Announcements
                            </h4>
                          </div>
                          <div>
                            <button
                              className=" btn-round btn-icon btn btn-success"
                              onClick={() => {
                                setFund("");
                                setAnnouncementDescription("");
                                setRedemptionDate(today);
                                setFromDate(today);
                                setToDate(today);
                                setOpeningExNav(0.0);
                                setClosingNavBeforeDistribution(0.0);
                                setPayoutRatio(0.0);
                                setDividendPerUnit(0.0);
                                setExNavAfterDistribution(0.0);
                                setSystemDate(today);
                                setViewAddModal(true);
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                {/* <tr>
                                  <td colSpan={4}>
                                    Fund Code: &nbsp; &nbsp;
                                    {fund}
                                  </td>
                                  <td colSpan={4}>
                                    AMC Code: &nbsp; &nbsp; {amc_code}
                                  </td>
                                  <td colSpan={4}>Market: &nbsp; &nbsp;</td>
                                </tr> */}
                                <tr>
                                  {/* <th>AMC</th> */}
                                  <th>Fund Name</th>
                                  <th>Description</th>
                                  <th>Date</th>
                                  <th>Redemption Date</th>
                                  <th>Start Date </th>
                                  <th>End Date</th>
                                  <th className="text-right">Opening Ex Nav</th>
                                  <th className="text-right">
                                    Closing Nav before distribution
                                  </th>
                                  <th className="text-right">Payout Ratio</th>
                                  <th className="text-right">
                                    Dividend Per Unit
                                  </th>
                                  <th className="text-right">
                                    Ex Nav after distribution
                                  </th>
                                  <th>Status</th>
                                  <th>Paid</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData.length !== 0
                                  ? tableData.map((data, index) => (
                                      <tr key={index}>
                                        {/* <td>{data.amc_code}</td> */}
                                        <td>{data.fund_code}</td>
                                        <td>{data.description}</td>
                                        <td>
                                          {data.created_at
                                            ? moment(data.created_at).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {data.redemption_date
                                            ? moment(
                                                data.redemption_date
                                              ).format("YYYY-MM-DD")
                                            : ""}
                                        </td>
                                        <td>
                                          {data.from_date
                                            ? moment(data.from_date).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {data.to_date
                                            ? moment(data.to_date).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.opening_ex_nav
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.closing_nav_before_dist
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.payout_ratio_percent
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            parseFloat(
                                              data.dividend_per_unit
                                            ).toFixed(4)
                                          )}
                                        </td>
                                        <td align="right">
                                          {numberWithCommas(
                                            data.ex_nav_after_dist
                                          )}
                                        </td>
                                        <td>
                                          {data.status === "active" ? (
                                            <span className="text-success">
                                              {data.status.toUpperCase()}
                                            </span>
                                          ) : (
                                            <span className="text-danger">
                                              {data.status.toUpperCase()}
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          {data.paid.toString() === "true" ? (
                                            <span className="text-success">
                                              {data.paid
                                                .toString()
                                                .toUpperCase()}
                                            </span>
                                          ) : (
                                            <span className="text-danger">
                                              {data.paid
                                                .toString()
                                                .toUpperCase()}
                                            </span>
                                          )}
                                        </td>
                                        <td className="d-flex justify-content-around">
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setViewId(data.announcement_id);
                                              setViewViewModal(true);
                                            }}
                                            data-tip="View Record"
                                            className="mr-1"
                                          >
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            <i className="fa fa-eye text-primary"></i>
                                          </div>
                                          <div
                                            style={{ cursor: "pointer" }}
                                            data-tip="Edit Record"
                                            onClick={() => {
                                              setModalLoading(false);
                                              editId = data.announcement_id;

                                              setAnnouncementId(
                                                data.announcement_id
                                              );
                                              setSystemDate(
                                                data.created_at_time
                                                  ? moment(
                                                      data.created_at_time
                                                    ).format("YYYY-MM-DD")
                                                  : ""
                                              );
                                              setFund(data.fund_code);
                                              setAnnouncementDescription(
                                                data.description
                                              );
                                              setRedemptionDate(
                                                data.redemption_time
                                                  ? moment(
                                                      data.redemption_time
                                                    ).format("YYYY-MM-DD")
                                                  : ""
                                              );
                                              setOpeningExNav(
                                                data.opening_ex_nav
                                              );
                                              setClosingNavBeforeDistribution(
                                                data.closing_nav_before_dist
                                              );
                                              setPayoutRatio(
                                                data.payout_ratio_percent
                                              );
                                              setDividendPerUnit(
                                                data.dividend_per_unit
                                              );
                                              setExNavAfterDistribution(
                                                data.ex_nav_after_dist
                                              );
                                              setFromDate(
                                                data.fromt_date_time
                                                  ? moment(
                                                      data.fromt_date_time
                                                    ).format("YYYY-MM-DD")
                                                  : ""
                                              );
                                              setToDate(
                                                data.to_date_time
                                                  ? moment(
                                                      data.to_date_time
                                                    ).format("YYYY-MM-DD")
                                                  : ""
                                              );
                                              setViewEditModal(true);
                                            }}
                                            className="mr-1 text-info"
                                          >
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            <i className="tim-icons icon-pencil"></i>
                                          </div>

                                          <div
                                            style={{ cursor: "pointer" }}
                                            data-tip="Change Active Status"
                                            onClick={() => {
                                              statusId = data.announcement_id;
                                              handleStatusChange();
                                            }}
                                            className="mr-1"
                                          >
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            {data.status === "active" ? (
                                              <i className="tim-icons icon-refresh-02 text-danger"></i>
                                            ) : (
                                              <i className="tim-icons icon-refresh-02 text-success"></i>
                                            )}
                                          </div>

                                          <div
                                            style={{ cursor: "pointer" }}
                                            data-tip="Change Paid Status"
                                            onClick={() => {
                                              paidId = data.announcement_id;
                                              handlePaidChange();
                                            }}
                                          >
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            {data.paid === "true" ? (
                                              <i className="tim-icons icon-simple-remove text-danger"></i>
                                            ) : (
                                              <i className="tim-icons icon-check-2 text-success"></i>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  : null}
                              </tbody>
                              {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                            </table>
                          </div>
                          {renderModalForAdd()}
                          {renderModalForView("")}
                          {renderModalForEdit("")}
                          {/* <div className="row">
                          <div className="col-md-6">
                            <button
                              className="btn btn-primary"
                              disabled={Boolean(Loading) || disabled}
                              // onClick={() => handleCreate()}
                              
                            >
                              {Loading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Loading...</span>
                                </>
                              ) : (
                                <span>Realize Portfolio</span>
                              )}
                            </button>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
                //   ) : null
              }
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DividendAnnouncement;
