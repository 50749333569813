import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";

const ViewTaxRate = () => {
  const taxRate: any = JSON.parse(
    sessionStorage.getItem("selected_tax_rate") || ""
  );
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";

  const [individual_filer, setIndividualFiler] = useState<any>(
    taxRate.individual_filer ? taxRate.individual_filer : "0"
  );
  const [individual_non_filer, setIndividualNonFiler] = useState<any>(
    taxRate.individual_non_filer ? taxRate.individual_non_filer : "0"
  );
  const [individual_exempted, setIndividualExempted] = useState<any>(
    taxRate.individual_exempted ? taxRate.individual_exempted : "0"
  );
  const [corporate_filer, setCorporateFiler] = useState<any>(
    taxRate.corporate_filer ? taxRate.corporate_filer : "0"
  );
  const [corporate_non_filer, setCorporateNonFiler] = useState<any>(
    taxRate.corporate_non_filer ? taxRate.corporate_non_filer : "0"
  );
  const [corporate_exempted, setCorporateExempted] = useState<any>(
    taxRate.corporate_exempted ? taxRate.corporate_exempted : "0"
  );

  const [category, setCategory] = useState(
    taxRate.category ? taxRate.category : ""
  );

  const [startDate, setStartDate] = useState(
    taxRate.start_date ? moment(taxRate.start_date).format("YYYY-MM-DD") : ""
  );
  const [endDate, setEndDate] = useState(
    taxRate.end_date ? moment(taxRate.end_date).format("YYYY-MM-DD") : ""
  );
  const [period, setPeriod] = useState(taxRate.period ? taxRate.period : "");
  const [cgtExempAfterYears, setCgtExempAfterYears] = useState(
    taxRate.cgt_exempt_after || "0"
  );
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header title-row">
                  <h4 className="card-title text-primary">
                    {" "}
                    <i
                      className="fa fa-angle-left mr-1 pointer"
                      onClick={() => {
                        history.replace("/admin/tax-rates");
                      }}
                    ></i>
                    View - Tax Rates
                  </h4>

                  <Link
                    to="/admin/tax-rates"
                    className="btn btn-primary btn-sm ml-auto"
                    replace
                  >
                    <i className="fa fa-eye mr-2"></i>View All
                  </Link>
                </div>

                <div className="card-body">
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Category</label>
                        <input
                          type="text"
                          placeholder="Category"
                          readOnly
                          className={`form-control  w-100 `}
                          value={category}
                        />
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group">
                        <label>Financial Year Start Date </label>
                        <input
                          type="date"
                          className={`form-control  w-100 `}
                          value={startDate}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Financial Year End Date</label>
                        <input
                          type="date"
                          className={`form-control  w-100 `}
                          value={endDate}
                          readOnly
                        />
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group">
                        {/* <label>Financial Year Period</label>	 */}
                        <label>Financial Period</label>
                        <input
                          type="text"
                          placeholder="Period"
                          readOnly
                          className={`form-control  w-100 `}
                          value={period}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Individual Filer{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control  w-100 text-right`}
                          value={individual_filer}
                          // onChange={(e) => {
                          //   setIndividualFiler(e.target.value);
                          // }}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Individual Non-Filer{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control  w-100 text-right`}
                          value={individual_non_filer}
                          // onChange={(e) => {
                          //   setIndividualNonFiler(e.target.value);
                          // }}
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Individual Exempted{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control  w-100 text-right`}
                          value={individual_exempted}
                          // onChange={(e) => {
                          //   setIndividualExempted(e.target.value);
                          // }}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Corporate Filer <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control w-100 text-right`}
                          value={corporate_filer}
                          // onChange={(e) => {
                          //   setCorporateFiler(e.target.value);
                          // }}
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Corporate Non-Filer{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control w-100 text-right`}
                          value={corporate_non_filer}
                          // onChange={(e) => {
                          //   setCorporateNonFiler(e.target.value);
                          // }}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>
                          Corporate Exempted{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control w-100 text-right`}
                          value={corporate_exempted}
                          // onChange={(e) => {
                          //   setCorporateExempted(e.target.value);
                          // }}
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group">
                        <label>CGT Exempt After Years </label>
                        <input
                          type="number"
                          className={`form-control w-100 text-right `}
                          value={cgtExempAfterYears}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewTaxRate;
