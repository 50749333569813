import React from "react";
import { useState } from "react";
import { searchAPI } from "./../stores/services/amc.service";
import { Modal } from "react-bootstrap";
import { Container, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  getFundByAmc,
  getInstrumentType,
} from "./../stores/services/funds.service";
import { getModeOfPayments } from "./../stores/services/transactions.service";
import { getAccountByFund } from "./../stores/services/account.service";
import { getAllBanks } from "./../stores/services/bank.service";
import { getBranchByBankName } from "./../stores/services/branch.service";
import { RiArrowGoForwardFill } from "react-icons/ri";
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from "./field";
import {
  addMegaTransaction,
  EditTransaction,
  getFlow,
} from "./../stores/services/beta-transaction.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { inWords } from "./../stores/services/template.service";
import { getBrokerByType } from "./../stores/services/broker.service";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  numberWithCommas,
  accountTitle_AccountNumber_Regex,
  getLedgerAccounts,
  getLedgersByTxn,
  removeEmptyLedgers,
} from "./../utils/customFunction";
import { Link } from "react-router-dom";

import AddUnitHolder from "./add-new-unitholder-txn";
import NumberFormat from "react-number-format";

import {
  getChartOfAccounts,
  getTransactionsLedgers,
} from "./../stores/services/chatofaccounts.service";
import { useSelector } from "react-redux";

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type: string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized: string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst: string;
  tax_wht_it: string;
  tax_wht_sst: string;
  tax_cgt: string;
  sale_load: string;
  redemption_load: string;
  low_balance_agree: string;
  offer_price: string;
  load_per_unit: string;
  percentage_of_discount: string;
  applicable_offer_price: string;
  sales_tax_collector: string;
  volume: string;
  par_value: string;
  announcement_date: string;
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend: string;
  tax_on_bonus: string;
  tax_on_right: string;
  zakat_amount: string;
  ledgers: string;
}

const FundManagementTransactions = (props: any) => {
  const history = useHistory();
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [headLabel, setHeadLabel] = React.useState("");
  const tx = sessionStorage.getItem("rejectedTxName") || "";
  const amcEdit = sessionStorage.getItem("rejectedAmc") || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  let txnTypes = useSelector((state: any) => state.txnTypes);
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState("");
  const [accLoading, setAccLoading] = useState(false);
  const paymentType = sessionStorage.getItem("payment_type") || "";

  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const amcName = sessionStorage.getItem("amc_name") || "";

  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data

  const [viewModalAddNewHolder, setViewModalAddNewUnitHolder] = useState(false);

  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);

  const [propsState, setPropsState] = useState({
    amc_code: "",
    folioNo: "",
    unitholder: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
  });

  // for legders states
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [counterLedgerAccounts, setCounterLegderAccounts] = useState<any>([]);

  const [transactionLedgers, setTransactionLedgers] = useState<any>([{}]);

  const [totalDebit, setTotalDebit] = useState("0");
  const [totalCredit, setTotalCredit] = useState("0");
  const [balance, setBalance] = useState("0");

  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === "Fund") {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(
        email,
        code,
        fund_code?.length > 0 ? fund_code : ""
      );
      // sort data
      let sortedData: any = [];
      sortedData = response.data.data.sort(function (a, b) {
        let x = a.nature.toLowerCase();
        let y = b.nature.toLowerCase();
        if (x > y) {
          return 1;
        }
        if (x < y) {
          return -1;
        }
        return 0;
      });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if (val.nature === "Special Managed Account") {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${"SMA"} `,
            value: val.symbol_code,
          };
        } else {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code})`,
            value: val.symbol_code,
          };
        }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) {}
  };

  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item: any) => {
      if (item.symbol_code === fund_code) {
        fields["nav"] = item.nav;
        fields["face_value"] = item.face_value;

        setFields(fields);

        let stock_market_data = [] as any;
        if (
          item.stock_market !== "" &&
          item.stock_market !== "[]" &&
          item.stock_market !== undefined
        ) {
          let stock_arr = JSON.parse(item.stock_market);
          stock_arr.map((item, ind) => {
            stock_market_data.push(item);
          });
          setStockMarketData(stock_market_data);
        } else {
          setStockMarketData([]);
        }
      }
    });
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code, amc_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          label: `${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) {}
    setAccLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem("rejectedFlag") || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    fields["txnr_type"] = props.match.params.type;

    // setFields(fields);
    counterAccounter = getCounterAccount(props.match.params.type);
    if (counterAccounter === "Other") {
      fields["counter_account_type"] = "Other";
      setFields(fields);
    }
    if (
      props.match.params.type === "debtmarketinvestment" ||
      props.match.params.type === "equityinvestment"
    ) {
      setGrossAmountReadOnly(true);
      setNetAmountReadOnly(true);
    }
  }, [props.match.params.type]);

  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  // filed Object Hook For geting data from fields / object for transaction creation
  let date = new Date();
  let disableDates = moment(date).format("YYYY-MM-DD");
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: "",
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: "",
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: "",
    next_coupon_date: "",
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  //code for edit transaction
  let [count, setCount] = useState(0);

  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem("rejectedTxObj") || " ");
      getFundByAMcCode(obj.amc_code);
      fields["payment_type"] = obj.payment_type || "";
      fields["txnr_type"] = obj.txnr_type || "";
      fields["amc_code"] = obj.amc_code || "";
      getAccountByFundName(obj.fund_code);
      fields["fund_code"] = obj.fund_code || "";
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields["instruction_date"] = obj.instruction_date || "";
      fields["execution_date"] = obj.execution_date || "";
      fields["executed_date"] = obj.executed_date || "";
      fields["fund_account"] = obj.fund_account || "";
      fields["dividend_date"] = obj.dividend_date || "";
      fields["dividend_rate"] = obj.dividend_rate || "";
      fields["reinvest"] = obj.reinvest || "";
      fields["period"] = obj.period || "";
      fields["account_title"] = obj.account_title || "";
      fields["account_no"] = obj.account_number || "";
      fields["bank"] = obj.bank || "";
      fields["branch"] = obj.branch || "";
      fields["counter_account_type"] = obj.counter_account_type || "";
      fields["counter_type"] = obj.counter_type || "";
      fields["counter_account_title"] = obj.counter_account_title || "";
      fields["counter_account_number"] = obj.counter_account_number || "";
      fields["counter_bank"] = obj.counter_bank || "";
      fields["counter_branch"] = obj.counter_branch || "";
      fields["mode_of_payment"] = obj.mode_of_payment || "";
      fields["instrument_no"] = obj.instrument_no || "";
      fields["instrument_type"] = obj.instrument_type || "";
      fields["instrument_date"] = obj.instrument_date || "";
      fields["realized_date"] = obj.realized_date || "";
      fields["realized"] = obj.realized || "";
      fields["gross_amount"] = obj.gross_amount.toString() || "";
      fields["net_amount"] = obj.net_amount.toString() || "";
      fields["dr_amount"] = "0";
      fields["cr_amount"] = "0";
      fields["balance"] = obj.balance.toString() || "";
      fields["folio_no"] = obj.folio_no || "";
      fields["unitholder_name"] = obj.unitholder_name || "";
      fields["units"] = obj.units.toString() || "";
      fields["nav"] = obj.nav.toString() || "";
      fields["sale_date"] = obj.sale_date || "";
      fields["current_holding"] = obj.current_holding.toString() || "";
      fields["total_holding"] = obj.total_holding.toString() || "";
      fields["symbol"] = obj.symbol || "";
      fields["dividend_percentage"] = obj.dividend_percentage.toString() || "";
      fields["credit_date"] = obj.credit_date || "";
      fields["maturity_type"] = obj.maturity_type || "";
      fields["security_type"] = obj.security_type || "";
      fields["issue_date"] = obj.issue_date || "";
      fields["conversion_date"] = obj.conversion_date || "";
      fields["associated_transaction"] = obj.associated_transaction || "";
      fields["maturity_date"] = obj.maturity_date || "";
      fields["coupon_rate"] = obj.coupon_rate || "";
      fields["price"] = obj.price.toString() || "";
      fields["face_value"] = obj.face_value.toString() || "";
      fields["money_market_face_value"] =
        obj.money_market_face_value.toString() || "";
      fields["detail"] = obj.detail || "";
      fields["type"] = obj.type || "";

      fields["redemption_date"] = obj.redemption_date || "";
      fields["remain_holding"] = obj.remain_holding.toString() || "";
      fields["settlement_by"] = obj.settlement_by || "";
      fields["tax_type"] = obj.tax_type || "";
      fields["settlement_date"] = obj.settlement_date || "";
      fields["total_charges"] = obj.total_charges.toString() || "";
      fields["sale_txn_no"] = obj.sale_txn_no || "";
      fields["return_date"] = obj.return_date || "";
      fields["trx_id"] = obj.txn_id || "";
      fields["primary_dealer"] = obj.primary_dealer;
      fields["yield"] = obj.yield || "";

      if (headLabel === "" || headLabel === "") {
        fields["sale_load"] = obj.sale_load || "";
      }

      if (headLabel === "" || headLabel === "") {
        fields["redemption_load"] = obj.redemption_load || "";
        fields["tax_cgt"] = obj.tax_cgt || "";
      }

      fields["zakat_amount"] = obj.zakat_amount;
      fields["txn_status"] = "CREATED";
      // Dividend On Investment
      fields["volume"] = obj.volume;
      fields["par_value"] = obj.par_value;
      fields["announcement_date "] = obj.announcement_date;
      fields["bonus_percentage"] = obj.bonus_percentage;
      fields["bonus_credit_date"] = obj.bonus_credit_date;
      fields["bonus_gross_volume"] = obj.bonus_gross_volume;
      fields["tax_it_bonus_amount"] = obj.tax_it_bonus_amount;
      fields["net_bonus_volume"] = obj.net_bonus_volume;
      fields["right_share_percentage"] = obj.right_share_percentage;
      fields["right_share_credit_date"] = obj.right_share_credit_date;
      fields["right_share_gross_volume"] = obj.right_share_gross_volume;
      fields["tax_it_right_share_amount"] = obj.tax_it_right_share_amount;
      fields["net_right_share_volume"] = obj.net_right_share_volume;
      fields["tax_on_dvidend"] = obj.tax_on_dvidend;
      fields["tax_on_bonus"] = obj.tax_on_bonus;
      fields["tax_on_right"] = obj.tax_on_right;
      count = count + 1;
      setCount(count);
      fields["fund_ips_account"] = obj.fund_ips_account || "";
      fields["counter_party_ips_account"] = obj.counter_party_ips_account || "";
      fields["low_balance_agree"] = "";
    }
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split(".")[0];
    let decimalVal = value.split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  React.useEffect(() => {
    if (!flag) {
      fields["payment_type"] = paymentType;
      fields["email"] = email;
      fields["amc_code"] = loggedInUser.amc_code;
    }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);

      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(
          sessionStorage.getItem("loggedInUser") || ""
        );
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {}

      try {
        // const response = await getChartOfAccounts(email);
        // if(response.data.status==200){

        let ledgersacc = await getLedgerAccounts(
          chartOfAccounts[0].family[3].sub_family
        );
        setLegderAccounts(ledgersacc);
        setCounterLegderAccounts(ledgersacc);
        //  renderCounterLedgersAccounts();
        // }else{
        //   toast.error(response.data.message);
        // }
      } catch (error) {}

      // get all transaction ledgers
      try {
        const response = await getTransactionsLedgers(email);
        if (response.data.status == 200) {
          let fundManagement =
            response.data.data.txn_catagory[1].fundTransferTxn;
          let txnLegders = await getLedgersByTxn(
            fundManagement,
            props.match.params.type
          );
          setTransactionLedgers(txnLegders);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {}

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: "Other",
          label: "Other",
        });
        setAmcdataCounter(temp);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }
      }

      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email, "", "", amc_code);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace("BANK_", "")} (${val.bank_name})`,
            value: val.bank_code.replace("BANK_", ""),
          };
        });
        temp.unshift({ value: "Other", label: "Other" });
        setBankNameData(temp);
      } catch (error) {}
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) {}
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) {}

      try {
        const brokerResponse = await getBrokerByType(
          email,
          counterAccounter,
          amc_code
        );
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: "Other", label: "Other" });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) {}

      setFlowLoading(false);
    };
    fetchAmc();
  }, []);

  //   const renderCounterLedgersAccounts=(data)=>{
  //     let temp = data.map((val) => {
  //         return {
  //           ...val,
  //           label: val.name,
  //           value: val.code
  //         };
  //       });
  //       setCounterLegderAccounts(temp);
  //   }

  const renderCounterLedgersAccounts = (type) => {
    return (
      counterLedgerAccounts &&
      counterLedgerAccounts
        .filter((item) => item.plsName == type)
        .map((item: any, index: number) => {
          return (
            <option key={index} value={item.code}>
              {item.account_title}
            </option>
          );
        })
    );
  };

  const renderFundAccounts = (type) => {
    return (
      ledgerAccounts &&
      ledgerAccounts
        .filter((item) => item.plsName == type)
        .map((item: any, index: number) => {
          //   if(item.plsName==='PLS ACCOUNTS'){
          //   return (
          //     <optgroup style={{
          //       color:'black'
          //     }} label="PLS ACCOUNTS">
          //     <option key={index} value={item.code}>
          //       {item.account_title}
          //     </option>
          //     </optgroup>
          //   );
          // }else{
          //   return (
          //     <optgroup style={{
          //       color:'black'
          //     }}  label="CURRENT ACCOUNTS">
          //     <option key={index} value={item.code}>
          //       {item.account_title}
          //     </option>
          //     </optgroup>
          //   );

          // }
          return (
            <option key={index} value={item.code}>
              {item.account_title} ({item.account_no})
            </option>
          );
        })
    );
  };

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name, amc_code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: "Other", label: "Other" });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name, amc_code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: "Other", label: "Other" });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const [inputType, setInputType] = useState(false);
  const onOtherSelection = (val) => {
    if (val === "Other") {
      setFields({
        ...fields,
        counter_branch: "",
      });
      setInputType(true);
    }
  };

  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === "") {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === "") {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState("");

  const createLegders = () => {
    if (headLabel == "fundtransfer") {
      transactionLedgers.map((item: any) => {
        if (item.type == "Credit") {
          item.amc_code = amc_code;
          item.fund_code = fields["fund_code"];

          item.balance = "";
          item.counter_account_number = fields["counter_account_number"];
          item.counter_account_title = fields["counter_account_title"];
          item.counter_bank = fields["counter_bank"];
          item.counter_branch = fields["counter_branch"];

          item.description = "Gross Amount & " + fields["detail"];
          item.instruction_date = fields["instruction_date"];
          item.instrument_by = "";
          item.instrument_date = fields["instrument_date"];
          item.instrument_no = fields["instrument_no"];
          item.instrument_type = fields["instrument_type"];
          item.realized = fields["realized"];
          item.realized_by = "";
          item.realized_date = fields["realized_date"];
          item.entered_by = "";
          item.evidence = "";
          item.period = sessionStorage.getItem("active_period") || "";
        }
        if (item.type == "Debit") {
          item.amc_code = amc_code;
          item.fund_code = fields["fund_code"];

          item.account_number = fields["counter_account_number"];
          item.account_title = fields["counter_account_title"];
          item.bank_code = fields["counter_bank"];
          item.branch = fields["counter_branch"];
          item.balance = "";
          item.counter_account_number = fields["account_no"];
          item.counter_account_title = fields["account_title"];
          item.counter_bank = fields["bank"];
          item.counter_branch = fields["branch"];

          item.description = "Gross Amount & " + fields["detail"];
          item.instruction_date = fields["instruction_date"];
          item.instrument_by = "";
          item.instrument_date = fields["instrument_date"];
          item.instrument_no = fields["instrument_no"];
          item.instrument_type = fields["instrument_type"];
          item.realized = fields["realized"];
          item.realized_by = "";
          item.realized_date = fields["realized_date"];
          item.entered_by = "";
          item.evidence = "";
          item.period = sessionStorage.getItem("active_period") || "";
        }
      });
      return transactionLedgers;
    }
  };

  //Create Transaction Api Call
  const createTransaction = async () => {
    try {
      if (!chechFieldDisplay(headLabel, "nav")) {
        fields["nav"] = "";
      }
      if (headLabel === "unitconversion") {
        let conversionAmount = +fields["net_amount"] / +unitNav;
        fields["total_holding"] = conversionAmount.toFixed(2);
      } else {
        fields["counter_type"] =
          counterType !== "" ? counterType : counterAccounter;
      }

      const isValid = await validate(); // check validation for Fields Data
      if (isValid) {
        if (
          headLabel === "fundtransfer" &&
          fields["counter_account_type"] === fields["fund_account"]
        ) {
          setLoading(false);
          toast.error("Both account should be not same");
        } else {
          setLoading(true);
          // if (flag) {
          //   if(headLabel==='saleofunit' || headLabel==='redemptionofunits'){
          //     fields['counter_account_type']=fields['counter_account_type'].replaceAll(fields['amc_code']+'-','')
          //     fields['counter_account_type']=fields['amc_code']+'-'+fields['counter_account_type'];
          //   }
          //   if(headLabel==='conversionin' || headLabel==='conversionout' || headLabel==='unitconversion'){
          //     fields['folio_no']=fields['folio_no'].replaceAll(fields['amc_code']+'-','');
          //     fields['folio_no']=fields['amc_code']+'-'+fields['folio_no'];
          //   }
          //   if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0 || parseFloat(fields['units'])<0 || parseFloat(fields['remain_holding'])<0 ){
          //     toast.error("Amounts or units or remaining holding are negative");
          //   }else if((headLabel==='redemptionofunits' && +fields['units']===0) || (headLabel==='conversionout' && +fields['units']===0) || (headLabel==='saleofunit' && +fields['units']===0)
          //   || (headLabel==='conversionin' && +fields['units']===0)){
          //     toast.error(" Units cannot be zero.");
          //   }
          //   else{
          //   try {
          //     const response = await EditTransaction(fields); // for Edit existed transaction
          //     if(response.data.status===400){
          //       setViewBankBalanceModal(true);
          //       toast.error(response.data.message);
          //     }else if(response.data.status===417){
          //       toast.warning(response.data.message);
          //     }else{
          //       setUnderStand(false);
          //       setViewBankBalanceModal(false);
          //       toast.success(response.data.message);
          //       setTimeout(() => {
          //         history.replace(`/admin/create-transaction`);
          //       }, 3000);
          //   }
          //   } catch (error:any) {
          //     setLoading(false);
          //     if(error.response!==undefined){
          //       toast.error(error.response.data.message);
          //       }else{
          //         toast.error('Something went wrong!');
          //       }
          //   }
          // }
          // } else {
          if (
            parseFloat(fields["net_amount"]) < 0 ||
            parseFloat(fields["gross_amount"]) < 0 ||
            parseFloat(fields["units"]) < 0 ||
            parseFloat(fields["remain_holding"]) < 0
          ) {
            setLoading(false);
            toast.error("Amounts or units or remaining holding are negative");
          } else if (
            (headLabel === "redemptionofunits" && fields["units"] === "0") ||
            (headLabel === "conversionout" && fields["units"] === "0") ||
            (headLabel === "saleofunit" &&
              (fields["units"] === "0" || fields["units"] === "0.0000")) ||
            (headLabel === "conversionin" &&
              (fields["units"] === "0" || fields["units"] === "0.0000"))
          ) {
            setLoading(false);

            toast.error(" Units cannot be zero.");
          } else {
            if (
              headLabel === "saleofunit" ||
              headLabel === "redemptionofunits"
            ) {
              fields["counter_account_type"] = fields[
                "counter_account_type"
              ].replaceAll(fields["amc_code"] + "-", "");
              fields["counter_account_type"] =
                fields["amc_code"] + "-" + fields["counter_account_type"];
              setLoading(false);
            }
            if (
              headLabel === "conversionin" ||
              headLabel === "conversionout" ||
              headLabel === "unitconversion"
            ) {
              fields["folio_no"] = fields["folio_no"].replaceAll(
                fields["amc_code"] + "-",
                ""
              );
              fields["folio_no"] =
                fields["amc_code"] + "-" + fields["folio_no"];
              setLoading(false);
            }
            if (+balance != 0) {
              toast.error(
                `Balance should be 0, Other wise you can't create transaction`
              );
              setLoading(false);

              return;
            }
            let finalLedgers = createLegders();
            // fields.ledgers=JSON.stringify(finalLedgers);
            fields.ledgers = JSON.stringify(removeEmptyLedgers(finalLedgers));
            try {
              const response = await addMegaTransaction(fields); // for add new transaction
              if (response.data.status === 400) {
                setViewBankBalanceModal(true);
                setLoading(false);
                toast.warning(response.data.message);
              } else if (response.data.status === 417) {
                setLoading(false);

                toast.warning(response.data.message);
              } else {
                setUnderStand(false);
                setViewBankBalanceModal(false);
                sessionStorage.setItem("last_id", response.data.txn_id);
                toast.success(
                  `${response.data.message} - ${response.data.txn_id}`
                );
                // toast.success(response.data.message);
                setTimeout(() => {
                  setLoading(false);

                  // history.replace(`/admin/create-transaction`);
                  // history.replace(`/admin/transactions-funds-management`);
                  history.goBack();
                }, 3000);
              }
            } catch (error: any) {
              setLoading(false);
              if (error.response !== undefined) {
                toast.error(error.response.data.message);
              } else {
                toast.error("Something went wrong!");
              }
            }
          }
          // }
          // setLoading(false);
        }
      } else {
        setLoading(false);
        setErrorShow(true);
        toast.error("Kindly Fill All the Fields");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [accTilteName, setAccTiltleName] = useState("");
  const [bankBalance, setBankBalance] = useState("");

  const setFundAccountFieldsData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["account_title"] = "";
      fields["account_no"] = "";
      fields["bank"] = "";
      fields["branch"] = "";
      fields["fund_account"] = "other";
      setFields(fields);
    } else {
      ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          // fields['fund_account'] = item.account_code;
          // fields['account_title'] = item.account_title;
          // fields['account_no'] = item.account_no;
          // fields['bank'] = item.bank;
          // fields['branch'] = item.branch;
          // setFields(fields);
          setFields({
            ...fields,
            fund_account: selected,
            account_title: item.account_title,
            account_no: item.account_no,
            bank: item.bank,
            branch: item.branch,
          });

          transactionLedgers.map((items: any) => {
            if (items.type == "Credit") {
              items.gl_code = +item.code;
              items.gl_name = item.bank.toString();
              items.sub_family_code = +item.plsCode;
              items.sub_family_name = item.plsName.toString();
            }
          });
        }
      });
    }
  };

  const setCounterFundAccountFieldsData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["counter_account_type"] = "other";
      setFields(fields);
    } else {
      ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          // fields['fund_account'] = item.account_code;
          // fields['account_title'] = item.account_title;
          // fields['account_no'] = item.account_no;
          // fields['bank'] = item.bank;
          // fields['branch'] = item.branch;
          // setFields(fields);
          setFields({
            ...fields,
            counter_account_type: selected,
            counter_account_title: item.account_title,
            counter_account_number: item.account_no,
            counter_bank: item.bank,
            counter_branch: item.branch,
          });
          transactionLedgers.map((items: any) => {
            if (items.type == "Debit") {
              items.gl_code = +item.code;
              items.gl_name = item.bank.toString();
              items.sub_family_code = +item.plsCode;
              items.sub_family_name = item.plsName.toString();
            }
          });
        }
      });
    }
  };

  const clearFundAccountFields = () => {
    setAccTiltleName("Unset");
    fields["fund_account"] = "";
    fields["account_title"] = "";
    fields["account_no"] = "";
    fields["bank"] = "";
    fields["branch"] = "";
    fields["nav"] = "";
    setFields(fields);
  };

  // Set Data in Fields from selected Broker
  const setBrokerData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      borkerByTypeData.map((item, index) => {
        if (item.company_code === selected) {
          setAccTiltleName(item.bank_name + "changes");
          fields["counter_account_type"] = item.company_code;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.iban;
          fields["counter_bank"] = item.bank_name;
          fields["counter_branch"] = item.branch;
          fields["sales_tax_collector"] = item.tax_collected;
          setFields(fields);
        }
      });
    }
  };

  // Set Data in Fields from selected Broker
  const setAmcAccountData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      amcdata.map((item, index) => {
        if (item.account_no === selected) {
          setAccTiltleName(item.account_no + "changes");
          getCounterBranchByName(item.bank);
          fields["counter_account_type"] = item.account_no;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.account_no;
          fields["counter_bank"] = item.bank;
          fields["counter_branch"] = item.branch;
          setFields(fields);
        }
      });
    }
  };

  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields["current_holding"] + "change");
    fields["counter_account_type"] = "";
    fields["counter_account_title"] = "";
    fields["counter_account_number"] = "";
    fields["counter_bank"] = "";
    fields["counter_branch"] = "";
    fields["current_holding"] = "";
    fields["folio_no"] = "";
    setFields(fields);
  };

  const [grossAmountInWords, setGrossAmountInWords] = useState("");
  const [netAmountInWords, setNetAmountInWords] = useState("");
  const [netAmountSeparator, setNetAmountSeparator] = useState("");
  const [grossAmountSeparator, setGrossAmountSeparator] = useState("");
  const [unitsSeparator, setUnitsSeparator] = useState("");
  const [unitsInWords, setUnitsInWord] = useState("");

  //retrun error border class
  const ErrorBorder = (value) => {
    if ((errorShow && value === "") || value.substring(0, 1) === "-") {
      return "required-border";
    } else {
      return "";
    }
  };
  //

  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false);
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input
                      type="number"
                      value={bankBalance || "0"}
                      readOnly
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields["detail"]}
                      className={"form-control w-100 "}
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setUnderStand(!underStand);
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: "Yes",
                          });
                          createTransaction();
                        }}
                      >
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderModalAddNewUnitHolder = () => {
    switch (viewModalAddNewHolder) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewModalAddNewUnitHolder(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewModalAddNewUnitHolder(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add UnitHolder</h6>
            </div>
            <div className="modal-body">
              <>
                <AddUnitHolder setPropState={setPropsState} />
              </>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer />
              <div className="">
                <div className="input-holder">
                  <div className="row">
                    <h4 className=" card-title">
                      <i
                        className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                        onClick={() => {
                          // history.replace("/admin/create-transaction");
                          // history.replace(`/admin/transactions-funds-management`);
                          history.goBack();
                        }}
                      ></i>
                      {CapTxnType(headLabel)} Transaction
                    </h4>
                  </div>

                  {/* <Link
                    to="/admin/bulk-upload"
                    className="btn btn-primary btn-sm mr-3"
                    replace
                  >
                    <i className="fa fa-upload mr-2"></i> Bulk Upload
                  </Link> */}
                </div>
                <div className=" ">
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Instruction</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group " data-tip="Select Fund">
                              Fund Name*
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accFundLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              ) : (
                                <select
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fields["fund_code"])
                                  }
                                  value={fields["fund_code"]}
                                  onChange={(e) => {
                                    fields["fund_code"] = e.target.value;
                                    setFields(fields);

                                    clearFundAccountFields();
                                    getAccountByFundName(e.target.value);
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {fundCheck
                                      ? "No Fund Found"
                                      : "Select Fund"}
                                  </option>
                                  {renderFundsDropdown()}
                                </select>
                              )}
                            </div>
                            <div className="form-group">
                              <div>Instruction Date</div>
                              <input
                                type="date"
                                value={fields["instruction_date"]}
                                readOnly
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["instruction_date"])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    instruction_date: e.target.value,
                                    //realized_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              {/* <div>Execution Date</div> */}
                              <div>Settlement Date</div>
                              <input
                                type="date"
                                value={fields["execution_date"]}
                                min={disableDates}
                                className={
                                  "form-control w-100" +
                                  ErrorBorder(fields["execution_date"])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    execution_date: e.target.value,
                                    settlement_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <div>Mode of Payment*</div>
                              <select
                                value={fields["mode_of_payment"]}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["mode_of_payment"])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    mode_of_payment: e.target.value,
                                  });
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {" "}
                                  Select Payment
                                </option>
                                {renderModeOfPayments()}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {headLabel === "fundtransfer"
                              ? "From Account"
                              : "Fund Account"}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div>
                            {/* hide field in conversion of units next button */}
                            <div className="form-group">
                              <div>Fund Account*</div>
                              {accLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              ) : fields["fund_code"].trim() === "" ? (
                                <div
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fields["fund_code"])
                                  }
                                >
                                  Kindly First Select Fund
                                </div>
                              ) : (
                                // <Select
                                // className="react-select info"
                                // classNamePrefix="react-select"
                                //   label={'Select'}
                                //   isDisabled={false}
                                //   onChange={(e) => {
                                //     fields['fund_account']=e.value;
                                //     setFields(fields);
                                //     setFundAccountFieldsData(e.value);
                                //   }}
                                //   value={ledgerAccounts.filter(
                                //     (option) =>
                                //       option.value === fields['fund_account']
                                //   )}
                                //   isClearable={false}
                                //   isSearchable={true}
                                //   name="color"
                                //   options={ledgerAccounts}
                                // />
                                <select
                                  className={
                                    "form-control " +
                                    ErrorBorder(fields["fund_account"])
                                  }
                                  value={fields["fund_account"]}
                                  onChange={(e) => {
                                    fields["fund_account"] = e.target.value;
                                    setFields(fields);
                                    setFundAccountFieldsData(e.target.value);

                                    let filterToAcc = ledgerAccounts.filter(
                                      (acc) =>
                                        acc.code.toString() !==
                                        e.target.value.toString()
                                    );
                                    setCounterLegderAccounts(filterToAcc);
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {" "}
                                    Select Fund Account
                                  </option>
                                  <optgroup
                                    style={{ color: "black" }}
                                    label="PLS Account"
                                  ></optgroup>
                                  {renderFundAccounts("PLS ACCOUNTS")}
                                  <optgroup
                                    style={{ color: "black" }}
                                    label="Current Account"
                                  ></optgroup>
                                  {renderFundAccounts("CURRENT ACCOUNTS")}
                                </select>
                              )}
                            </div>

                            <div className="form-group">
                              <div>Account Title*</div>
                              <input
                                type="text"
                                value={fields.account_title}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["account_title"])
                                }
                                readOnly
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    account_title: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <div>Account Number*</div>
                              <input
                                type="text"
                                value={fields["account_no"]}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["account_no"])
                                }
                                readOnly
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    account_no: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group w-100 d-flex">
                              <div className="w-50 flex mr-2">
                                <div>Bank</div>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    value={fields["bank"]}
                                    readOnly
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        bank: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                {/* <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            label={'Select Bank'}
                            isDisabled={false}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                bank: e.value,
                              });
                              getBranchByName(e.value);
                            }}
                            value={bankNameData.filter(
                              (option) => option.value === fields['bank']
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={bankNameData}
                          /> */}
                              </div>
                              <div className="w-50 flex">
                                <div>Branch</div>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    value={fields["branch"]}
                                    readOnly
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        branch: e.target.value,
                                      });
                                    }}
                                  />
                                </div>

                                {/* {branchInputType ? (
                            <div className="d-flex">
                              <input
                                type="text"
                                value={fields['branch']}
                                className="form-control w-100 "
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    branch: e.target.value,
                                  });
                                }}
                              />
                              <RiArrowGoForwardFill
                                onClick={() => {
                                  setBranchInputType(false);
                                  setFields({
                                    ...fields,
                                    branch: '',
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select Branch'}
                              isDisabled={false}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  branch: e.value,
                                });
                                onOtherBranchSelection(e.value);
                              }}
                              value={allbranchesData.filter(
                                (option) => option.value === fields['branch']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={allbranchesData}
                            />
                          )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="card">
                        <div className="card-header">
                          {/* condition to for label selection */}
                          <h4 className="card-title">
                            {headLabel === "fundtransfer"
                              ? "To Account"
                              : "Counter Account"}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group">
                              <ReactTooltip
                                textColor="black"
                                backgroundColor="white"
                                effect="float"
                              />

                              <div className="text-capitalize">
                                {counterAccounter === "Fund"
                                  ? "Fund Account"
                                  : counterAccounter}
                              </div>

                              {counterAccounter === "Fund" ? (
                                //For fund
                                <div data-tip="First Select Fund Name">
                                  <ReactTooltip
                                    textColor="white"
                                    backgroundColor="black"
                                    effect="float"
                                  />
                                  {accLoading ? (
                                    <div className="form-control w-100">
                                      <i className="fa fa-spinner fa-spin fa-1x"></i>
                                    </div>
                                  ) : fields["fund_code"].trim() === "" ? (
                                    <div className="form-control    w-100">
                                      First Select Fund
                                    </div>
                                  ) : (
                                    // <Select
                                    // className="react-select info"
                                    // classNamePrefix={"react-select "+ ErrorBorder(fields['counter_account_type'])}
                                    //   label={'Select'}
                                    //   isDisabled={false}
                                    //   onChange={(e) => {
                                    //     setFields({
                                    //       ...fields,
                                    //       counter_account_type: e.value,
                                    //     });
                                    //     setFundDataCounter(e.value);
                                    //   }}
                                    //   value={toAccountData.filter(
                                    //     (option) =>
                                    //       option.value ===
                                    //       fields['counter_account_type']
                                    //   )}
                                    //   isClearable={false}
                                    //   isSearchable={true}
                                    //   name="color"
                                    //   options={toAccountData}
                                    // />

                                    <select
                                      className={
                                        "form-control " +
                                        ErrorBorder(
                                          fields["counter_account_type"]
                                        )
                                      }
                                      value={fields["counter_account_type"]}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          counter_account_type: e.target.value,
                                        });
                                        setCounterFundAccountFieldsData(
                                          e.target.value
                                        );
                                        // setFundAccountFieldsData(e.target.value);
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {" "}
                                        Select Fund Account
                                      </option>
                                      {/* {renderCounterLedgersAccounts()} */}
                                      <optgroup
                                        style={{ color: "black" }}
                                        label="PLS Account"
                                      ></optgroup>
                                      {renderCounterLedgersAccounts(
                                        "PLS ACCOUNTS"
                                      )}
                                      <optgroup
                                        style={{ color: "black" }}
                                        label="Current Account"
                                      ></optgroup>
                                      {renderCounterLedgersAccounts(
                                        "CURRENT ACCOUNTS"
                                      )}
                                    </select>
                                  )}
                                </div>
                              ) : //For Other Counter Amc
                              counterAccounter === "amc" ? (
                                accFundLoading ? (
                                  <div className="form-control w-100">
                                    <i className="fa fa-spinner fa-spin fa-1x"></i>
                                  </div>
                                ) : (
                                  <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    label={"Select"}
                                    isDisabled={false}
                                    onChange={(e) => {
                                      let taxCollector = "";
                                      if (e.value === "Other") {
                                        taxCollector = "Other";
                                      } else {
                                        let amcTaxCollector =
                                          amcdataCounter.filter(
                                            (item) => item.value === e.value
                                          );

                                        taxCollector =
                                          amcTaxCollector[0].tax_collector;
                                      }

                                      fields["counter_account_type"] = e.value;
                                      fields["sales_tax_collector"] =
                                        taxCollector;
                                      setFields(fields);
                                      // setFields({
                                      //   ...fields,
                                      //   counter_account_type: e.value,
                                      // });
                                      setAmcAccountData(e.value);
                                    }}
                                    value={amcdataCounter.filter(
                                      (option) =>
                                        option.value ===
                                        fields["counter_account_type"]
                                    )}
                                    isClearable={false}
                                    isSearchable={true}
                                    name="color"
                                    options={amcdataCounter}
                                  />
                                )
                              ) : //For Other Counter Accounts

                              counterAccounter === "Other" ? (
                                <input
                                  type="text"
                                  value={fields["counter_account_type"]}
                                  className="form-control w-100"
                                  readOnly
                                />
                              ) : (
                                //For Other Brokers
                                // check the conversion of unit next button
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  label={"Select"}
                                  isDisabled={false}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      counter_account_type: e.value,
                                    });
                                    setBrokerData(e.value);
                                  }}
                                  value={borkerByTypeData.filter(
                                    (option) =>
                                      option.value ===
                                      fields["counter_account_type"]
                                  )}
                                  isClearable={false}
                                  isSearchable={true}
                                  name="color"
                                  options={borkerByTypeData}
                                />
                              )}
                            </div>

                            <div className="form-group">
                              <div>Account Title</div>
                              <input
                                type="text"
                                value={fields["counter_account_title"]}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["counter_account_title"])
                                }
                                readOnly
                                onChange={(e) => {
                                  if (
                                    e.target.value.match(
                                      accountTitle_AccountNumber_Regex
                                    ) ||
                                    e.target.value == ""
                                  ) {
                                    counterType = "Other";
                                    setCounterType(counterType);
                                    setFields({
                                      ...fields,
                                      counter_account_title:
                                        e.target.value || "",
                                    });
                                  }
                                  // setFields({
                                  //   ...fields,
                                  //   counter_account_title: e.target.value,
                                  // });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <div>Account Number</div>
                              <input
                                type="text"
                                value={fields["counter_account_number"]}
                                className={"form-control w-100 "}
                                readOnly
                                onChange={(e) => {
                                  if (
                                    e.target.value.match(
                                      accountTitle_AccountNumber_Regex
                                    ) ||
                                    e.target.value == ""
                                  ) {
                                    counterType = "Other";
                                    setCounterType(counterType);
                                    setFields({
                                      ...fields,
                                      counter_account_number: e.target.value,
                                    });
                                  }
                                  // setFields({
                                  //   ...fields,
                                  //   counter_account_number: e.target.value,
                                  // });
                                }}
                              />
                            </div>
                            <div className="form-group w-100 d-flex">
                              <div className="w-50 flex mr-2">
                                <div>Bank</div>
                                {/* <div className="form-group">
                          <input className="form-control"
                              value={fields['counter_bank']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_bank: e.target.value,
                                  });
                                }} />
                                </div> */}
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  label={"Select Bank"}
                                  // isDisabled={false}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      counter_bank: e.value,
                                    });
                                    getCounterBranchByName(e.value);
                                  }}
                                  value={bankNameData.filter(
                                    (option) =>
                                      option.value === fields["counter_bank"]
                                  )}
                                  isClearable={false}
                                  isSearchable={true}
                                  name="color"
                                  options={bankNameData}
                                />
                              </div>

                              <div className="w-50 flex mr-2">
                                <div>Branch</div>
                                <div className="form-group">
                                  {/* <input className="form-control"
                              value={fields['counter_branch']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                  termDepositEntity['branch']=e.target.value;
                                  setTermDepositEntity(termDepositEntity);
                                }} /> */}

                                  {inputType ? (
                                    <div className="d-flex">
                                      <input
                                        type="text"
                                        value={fields["counter_branch"]}
                                        className="form-control w-100 "
                                        onChange={(e) => {
                                          setFields({
                                            ...fields,
                                            counter_branch: e.target.value,
                                          });
                                        }}
                                      />
                                      <RiArrowGoForwardFill
                                        onClick={() => {
                                          setInputType(false);
                                          setFields({
                                            ...fields,
                                            counter_branch: "",
                                          });
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <Select
                                      className="react-select info"
                                      classNamePrefix="react-select"
                                      label={"Select Branch"}
                                      // isDisabled={false}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          counter_branch: e.branch_name,
                                        });
                                        onOtherSelection(e.value);
                                      }}
                                      value={allCounterbranchesData.filter(
                                        (option) =>
                                          option.value ===
                                          fields["counter_branch"]
                                      )}
                                      isClearable={false}
                                      isSearchable={true}
                                      name="color"
                                      options={allCounterbranchesData}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Instrument</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group">
                              <div>Instrument Type</div>
                              <select
                                className={"form-control w-100 "}
                                value={fields["instrument_type"]}
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    instrument_type: e.target.value,
                                  });
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {" "}
                                  Select Type
                                </option>
                                {renderiTypeDataDropdown()}
                              </select>
                            </div>
                            {/* {fields.payment_type === "Receipt" && (
                              <> */}
                            <div className="form-group">
                              <div>Instrument No./Reference No</div>
                              <input
                                defaultValue={fields["instrument_no"]}
                                type="text"
                                className={"form-control w-100 "}
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    instrument_no: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <div>Instrument Date / Deposit Date</div>
                              <input
                                type="date"
                                defaultValue={fields["instrument_date"]}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["instrument_date"])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    instrument_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            {/* </>
                            )} */}

                            {/* <div className="form-check">
                              <label className="form-check-label1">
                                <input type="checkbox" className="form-check-input" value="" checked={realize}
                                onChange={(e)=>{
                                  let flag='';	                                  
                                  if(realize===true){
                                    setRealize(!realize);
                                    flag='false';
                                  }else{
                                    setRealize(!realize);
                                    flag='true';
                                  }
                                  setFields({
                                    ...fields,
                                    realized: flag,
                                  });
                                }}
                                />
                                <span className="form-check-sign"><span className="check"></span></span>
                               Realized in Bank
                                </label>
                           </div>
                      
                            <div className="form-group">
                              <input
                                type="date"
                                defaultValue={fields['realized_date']}
                                className={'form-control w-100 '}
                                onChange={(e) => {
                                  let date =  moment(e.target.value).format('YYYY-MM-DD');
                                  setFields({
                                    ...fields,
                                    realized_date: date,
                                  });
                                }}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Transaction Details</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            {chechFieldDisplay(headLabel, "folio_no") && (
                              <Row>
                                <Col md="6">
                                  <div className="form-group">
                                    <div>Folio Number*</div>
                                    <input
                                      type="text"
                                      value={fields["folio_no"]}
                                      className={
                                        "form-control w-100" +
                                        ErrorBorder(fields["folio_no"])
                                      }
                                      readOnly
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          folio_no: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>

                                <Col md="6">
                                  <div className="form-group">
                                    <div>UnitHolder Name*</div>
                                    <input
                                      type="text"
                                      value={fields["unitholder_name"]}
                                      className={
                                        "form-control w-100 " +
                                        ErrorBorder(fields["unitholder_name"])
                                      }
                                      readOnly
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          unitholder_name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )}

                            {chechFieldDisplay(
                              headLabel,
                              "associated_transaction"
                            ) && (
                              <div className="form-group">
                                <div>Associated Txn No*</div>
                                <input
                                  type="text"
                                  defaultValue={
                                    fields["associated_transaction"]
                                  }
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(
                                      fields["associated_transaction"]
                                    )
                                  }
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      associated_transaction: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            )}

                            {chechFieldDisplay(headLabel, "face_value") && (
                              <div className="form-group">
                                <div>Face Value*</div>
                                <input
                                  type="number"
                                  value={fields["face_value"]}
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fields["face_value"])
                                  }
                                  readOnly
                                  onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2);
                                    setFields({
                                      ...fields,
                                      face_value: value,
                                    });
                                  }}
                                />
                              </div>
                            )}

                            {chechFieldDisplay(headLabel, "detail") && (
                              <div className="form-group">
                                <div>Detail*</div>
                                <input
                                  type="text"
                                  defaultValue={fields["detail"]}
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fields["detail"])
                                  }
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      detail: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            )}

                            {chechFieldDisplay(headLabel, "tax_type") && (
                              <div className="form-group mt-2">
                                <div>Tax Type*</div>
                                <select
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fields["tax_type"])
                                  }
                                  defaultValue={fields["tax_type"]}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      tax_type: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {" "}
                                    Select Type
                                  </option>
                                  {renderTaxTypeDropdown()}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Amount</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group">
                              <div>
                                Gross Amount*{" "}
                                {/* {grossAmountSeparator === "" ? (
                                  ""
                                ) : (
                                  <span className="float-right text-primary">
                                    {grossAmountSeparator}
                                  </span>
                                )} */}
                              </div>
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                decimalScale={2}
                                style={{ textAlign: "right" }}
                                value={fields["gross_amount"]}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["gross_amount"])
                                }
                                readOnly={grossAmountReadOnly}
                                onChange={(e) => {
                                  let value = decimalValue(
                                    e.target.value?.replaceAll(",", ""),
                                    2
                                  );
                                  if (value.split(".")[0].length <= 12) {
                                    // setTotalRemainHolding(value,"GrossAmount");
                                    // value==='' ? setUnitsReadOnly(false) : setUnitsReadOnly(true);
                                    // value==='' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);

                                    setFields({
                                      ...fields,
                                      gross_amount: value,
                                      net_amount: value,
                                    });
                                    let inwordNetAmount = inWords(value);
                                    setNetAmountInWords(inwordNetAmount);
                                    let inwordGrossAmount = inWords(value);
                                    setGrossAmountInWords(inwordGrossAmount);
                                    let grossAmountSeparator =
                                      numberWithCommas(value);
                                    setGrossAmountSeparator(
                                      grossAmountSeparator
                                    );
                                    let netAmountSeparator =
                                      numberWithCommas(value);
                                    setNetAmountSeparator(netAmountSeparator);
                                  }

                                  let totaldebit = 0,
                                    totalcredit = 0;
                                  if (headLabel == "fundtransfer") {
                                    transactionLedgers.map((item: any) => {
                                      if (item.type == "Debit") {
                                        item.debit_amount = value;
                                        item.credit_amount = "0";
                                        totaldebit += +item.debit_amount;
                                      }
                                      if (item.type == "Credit") {
                                        item.debit_amount = "0";
                                        item.credit_amount = value;
                                        totalcredit += +item.credit_amount;
                                      }
                                    });
                                    setTotalDebit(
                                      totaldebit.toFixed(2).toString()
                                    );
                                    setTotalCredit(
                                      totalcredit.toFixed(2).toString()
                                    );
                                    setBalance(
                                      (+totaldebit - +totalcredit)
                                        .toFixed(2)
                                        .toString()
                                    );
                                  }
                                }}
                              />
                              <small
                                className="truncate"
                                title={grossAmountInWords}
                              >
                                {grossAmountInWords === ""
                                  ? ""
                                  : grossAmountInWords}
                              </small>
                            </div>

                            <div className="form-group">
                              {/* <div className="d-flex"> */}
                              <div>
                                Net Amount*{" "}
                                {/* {netAmountSeparator === "" ? (
                                  ""
                                ) : (
                                  <span className="float-right text-primary">
                                    {netAmountSeparator}
                                  </span>
                                )} */}
                              </div>

                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                decimalScale={2}
                                style={{ textAlign: "right" }}
                                value={fields["net_amount"]}
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["net_amount"])
                                }
                                //   readOnly={netAmountReadOnly}
                                readOnly={true}
                                onChange={(e) => {
                                  let value = decimalValue(
                                    e.target.value?.replaceAll(",", ""),
                                    2
                                  );
                                  if (value.split(".")[0].length <= 12) {
                                    // setTotalRemainHolding(value,"NetAmount");
                                    if (value !== "") {
                                      setFields({
                                        ...fields,
                                        net_amount: value,
                                      });
                                      let a = inWords(value);
                                      setNetAmountInWords(a);

                                      //// separator
                                      let netAmountSeparator =
                                        numberWithCommas(value);
                                      setNetAmountSeparator(netAmountSeparator);
                                      if (headLabel == "fundtransfer") {
                                        // setTotalDebit(value);
                                        setTotalCredit(
                                          (
                                            +fields["gross_amount"] + +value
                                          ).toString()
                                        );
                                        let totalbalance = (
                                          +fields["gross_amount"] - +value
                                        ).toString();
                                        setBalance(totalbalance);
                                      }
                                    } else {
                                      setNetAmountInWords("");
                                      setNetAmountSeparator("");
                                    }
                                  }
                                }}
                              />
                              <small
                                className="truncate"
                                title={netAmountInWords}
                              >
                                {netAmountInWords === ""
                                  ? ""
                                  : netAmountInWords}
                              </small>
                            </div>

                            <div className="form-group">
                              <div> Payment Type </div>
                              <div className="form-control">
                                {fields["payment_type"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title text-primary">
                            Ledgers
                            {/* <button className="btn-round btn-icon btn btn-success float-right" onClick={() => {
                              setViewModalForLedgers(!viewModalForLedgers);
                          }}> <i className="fa fa-plus"></i> </button> */}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Ledger</th>
                                  <th className="text-right">Debit Amount</th>
                                  <th className="text-right">Credit Amount</th>
                                  <th>Type</th>
                                  <th>Ledger Family</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {transactionLedgers.map(
                                  (item: any, index: number) => {
                                    return (
                                      <tr>
                                        <td>
                                          <label>
                                            {item.gl_name} <br />
                                            {item.gl_code == "" ? (
                                              ""
                                            ) : (
                                              <small className="text-muted">
                                                ({item.gl_code})
                                              </small>
                                            )}
                                          </label>
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.debit_amount)}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.credit_amount)}
                                        </td>
                                        <td>{item.type}</td>
                                        <td>{item.main_family_name}</td>
                                        {/* <td>
                            <IoIosClose
                              style={{ fontSize: '25px', color: 'red' }}
                              onClick={() => {
                                let array = [...transactionLedgers];
                                array.splice(index, 1);
                                setTransactionLedgers(array);
                                let totaldebit=0, totalcredit=0;
                                array.map((items, index) => {
                                  if(items.type=='Debit'){
                                    totaldebit+= +items.debit_amount;
                                    }
                                  if(items.type=='Credit'){
                                    totalcredit+= +items.credit_amount;
                                  }
                                });
                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                              }}
                            />
                            </td> */}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td></td>
                                  <td className="text-right">
                                    Total Debit:{" "}
                                    <span className="text-primary">
                                      {numberWithCommas(totalDebit)}
                                    </span>
                                  </td>
                                  <td className="text-right">
                                    Total Credit:{" "}
                                    <span className="text-primary">
                                      {numberWithCommas(totalCredit)}
                                    </span>
                                  </td>
                                  <td className="text-right">
                                    Balance:{" "}
                                    <span className="text-primary">
                                      {numberWithCommas(balance)}
                                    </span>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setLoading(true);
                          createTransaction();
                        }}
                        disabled={Boolean(Loading)}
                      >
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>
                            {tx === "cashdividend" ? "Update" : "Create"}
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {renderModalForBankBalance()}
                {renderModalAddNewUnitHolder()}
              </div>
              <div style={{ display: "none" }}>{accTilteName}</div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FundManagementTransactions;
