import React, { useState, useEffect, useContext } from "react";

import { getAllBanks } from "../../../stores/services/bank.service";
import { getAmc } from "../../../stores/services/amc.service";
import { getBranchByBankName } from "../../../stores/services/branch.service";
import { EditAccount } from "../../../stores/services/account.service";
import { getFundByAmc } from "../../../stores/services/funds.service";
import ReactTooltip from "react-tooltip";
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {
  accountTitle_AccountNumber_Regex,
  getAllLedgers,
} from "../../../utils/customFunction";
import "react-phone-input-2/lib/style.css";
import { RiGitRepositoryLine } from "react-icons/ri";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { set } from "mobx";
import { replaceChartOfAccountsFile } from "../../../stores/services/chatofaccounts.service";
import { GetChartOfAccount } from "../../../redux/actions/chartOfAccount-Actions";

const EditAccountComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const obj = JSON.parse(sessionStorage.getItem("accountObj") || "{}");
  const context = useContext(ThemeContext);
  const amcCode = sessionStorage.getItem("amc_code") || "";
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  //hooks for getting all inputs from user
  const [bankname, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [amcName, setAmcName] = useState("");
  const [city, setCity] = useState("");
  const [fund, setFund] = useState("");
  const [currency, setCurrency] = useState("PKR");
  const [txNature, setTxNature] = useState("");
  const [iban, setIban] = useState("");
  const [opHeadEmail, setHeadEmail] = useState("");
  const [accType, setAccType] = useState("");
  const [accPurpose, setAccPurpose] = useState("");
  const [amount, setAmount] = useState<any>("");
  const email = sessionStorage.getItem("email") || "";
  const [fundLoading, setFundLoading] = useState<boolean>(false);
  const [smaCheck, setSmaCheck] = useState<boolean>(false);
  const [acc_code, setAccCode] = useState("");

  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceError, setCurrentBalanceError] =
    useState<boolean>(false);

  const [interestRate, setInterestRate] = useState<any>("");
  const [debitBankProfitLedger, setDebitBankProfitLedger] = useState<any>();
  const [creditBankProfitLedger, setCreditBankProfitLedger] = useState<any>();
  const [associatedLedgerExpense, setAssociatedLedgerExpense] = useState<any>();
  const [interestRateError, setInterestRateError] = useState<boolean>(false);
  const [debitBankProfitLedgerError, setDebitBankProfitLedgerError] =
    useState<boolean>(false);
  const [creditBankProfitLedgerError, setCreditBankProfitLedgerError] =
    useState<boolean>(false);
  const [associatedLedgerExpenseError, setAssociatedLedgerExpenseError] =
    useState<boolean>(false);

  const [ledgerOption, setLedgerOption] = useState<any>([]);
  const [allLedgerData, setAllLedgerData] = useState<any>([]);

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);
  const [ledgersOptionFlag, setLedgersOptionFlag] = useState(false);

  useEffect(() => {
    if (chartOfAccounts?.length > 0) {
      setLedgersData();
    }
  }, [chartOfAccounts]);

  const setLedgersData = async () => {
    let temp = await getAllLedgers(chartOfAccounts);
    setAllLedgerData([...temp]);
  };

  useEffect(() => {
    setLedgersOptionFlag(false);
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.name}`,
              value: item.code,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.bank}`,
              value: item.code,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
            value: item.code,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
            value: item.code,
          },
        ]);
      }
    });

    allLedgerData.length > 0 && setLedgersOptionFlag(true);
  }, [allLedgerData]);

  useEffect(() => {
    if (obj.gl_code && ledgersOptionFlag == true) {
      let findAccountsDetails = chartOfAccounts[0].family[3].sub_family
        ?.find(
          (item) =>
            item.name ==
            (obj.nature_of_account == "Current"
              ? "CURRENT ACCOUNTS"
              : "PLS ACCOUNTS")
        )
        ?.gl.find((item) => item.code == obj.gl_code);
      setInterestRate(findAccountsDetails?.interest_rate || "");
      setCurrentBalance(findAccountsDetails?.balance || "0");
      if (
        ledgerOption.find(
          (item) => item.value == findAccountsDetails.related_ledgers[0]
        )
      ) {
        setDebitBankProfitLedger(
          ledgerOption.find(
            (item) => item.value == findAccountsDetails.related_ledgers[0]
          )
        );
      }
      if (
        ledgerOption.find(
          (item) => item.value == findAccountsDetails.related_ledgers[1]
        )
      ) {
        setCreditBankProfitLedger(
          ledgerOption.find(
            (item) => item.value == findAccountsDetails.related_ledgers[1]
          )
        );
      }
    }
  }, [ledgersOptionFlag]);

  useEffect(() => {
    setBranchHeadEmail(obj.bm_email);
    setFolioNo(obj.folio_no);
    setAccCode(obj.account_code);
    setBankName(obj.bank_name);
    setBranchName(obj.branch_name);
    setAmcName(obj.amc_code);
    getfundByAmcCode(obj.amc_code);
    getBranchByName(obj.bank_code);
    if (obj.sma === "true") {
      setSmaCheck(true);
    } else {
      setSmaCheck(false);
    }
    // setSmaCheck(true);
    setFund(obj.fund_code);
    console.log("SELECTED ACCOUNT => ", obj);
    setCurrency(obj.currency);
    setBranchHeadMobile(obj.bm_phone);
    setTxNature(obj.nature_of_account);
    setIban(obj.account_no);
    setHeadPhone(obj.operation_head_phone);
    setAccType(obj.account_title);
    setAccPurpose(obj.product_purpose);
    setHeadEmail(obj.operation_head_email);
    setAmount(obj.balance_amount);
    setCity(obj.bank_city);
    // const fetchAmcFirst = async () => {
    //   try {
    //     const amcResponse = await getAmc(email);
    //     setAmcdata(amcResponse.data.data);
    //   } catch (error) {}
    // };
    // fetchAmcFirst();
    // setAmount(obj.)
  }, []);

  const getfundByAmcCode = async (code: string) => {
    setFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(
        email,
        code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
    } catch (error) {}
    setFundLoading(false);
  };
  //error getting hooks

  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [amcNameError, setAmcError] = useState("");
  const [cityError, setCityError] = useState("");
  const [fundError, setFundError] = useState("");
  const [currencyError, setCurrencyError] = useState("");
  const [txNatureError, setTxNatureError] = useState("");
  const [ibanError, setIbanError] = useState("");
  const [accTypeError, setAccTypeError] = useState("");
  const [accPurposeError, setAccPurposeError] = useState("");
  const [amountError, setAmountError] = useState("");

  const [Loading, setLoading] = useState(false);
  //for dropdown data
  const [amcdata, setAmcdata] = useState<any>([]);
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const getBranchByName = async (name: string) => {
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name, amcCode);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };
  const [headEmailError, setHeadEmailError] = useState("");

  const [folioNoError, setFolioNoError] = useState("");
  const [headPhone, setHeadPhone] = useState("");
  const [branchHeadEmail, setBranchHeadEmail] = useState("");
  const [branchHeadMobile, setBranchHeadMobile] = useState("");
  const [folioNo, setFolioNo] = useState("");
  const [headPhoneError, setHeadPhoneError] = useState("");
  const [branchHeadEmailError, setBranchHeadEmailError] = useState("");
  const [branchHeadMobileError, setBranchHeadMobileError] = useState("");
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);

  useEffect(() => {
    const fetchAmc = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all Amc for dropdown
      // try {
      //   const amcResponse = await getAmc(email);
      //   setAmcdata(amcResponse.data.data);
      // } catch (error) {}
      //get all banks list for dropdown
      try {
        const response = await getAllBanks(email, "", "", amcCode);
        setBankNameData(response.data.data);
        // try {
        //   setAllBranchesData(response.data.data);
        //   setBankName(bankname)
        // } catch (error) { }
      } catch (error) {}

      //get tx nature data for dropdown
    };
    fetchAmc();
  }, []);

  //render dropdown for bank name data
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace("BANK_", "")}>
          {item.bank_code.replace("BANK_", "")}-{item.bank_name}
        </option>
      );
    });
  };
  //render dropdown for branch name data
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };
  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name}-{item.symbol_code}
        </option>
      );
    });
  };
  //render dropdown for nature of transaction data
  const validate = () => {
    let bankErr,
      branchErr,
      amcErr,
      fundErr,
      currencyErr = "";
    let txErr,
      ibanErr,
      accErr,
      accPurErr,
      amountErr,
      headEmailErr,
      headPhoneErr,
      branchHEmailerr,
      cityErr,
      interestRateErr,
      debitBankProfitLedgerErr,
      creditBankProfitLedgerErr,
      BranchHPhoneErr = "";
    bankname.trim() === "" ? (bankErr = "Required") : (bankErr = "");
    branchName.trim() === "" ? (branchErr = "Required") : (branchErr = "");
    amcName.trim() === "" ? (amcErr = "Required") : (amcErr = "");
    fund.trim() === "" ? (fundErr = "Required") : (fundErr = "");
    // currency.trim() === '' ? currencyErr = "Required" : currencyErr = "";
    txNature.trim() === "" ? (txErr = "Required") : (txErr = "");
    iban.trim() === "" ? (ibanErr = "Required") : (ibanErr = "");
    accType.trim() === "" ? (accErr = "Required") : (accErr = "");
    city.trim() === "" ? (cityErr = "Required") : (cityErr = "");
    interestRate.trim() === ""
      ? (interestRateErr = "required-border")
      : (interestRateErr = "");
    !debitBankProfitLedger?.value
      ? (debitBankProfitLedgerErr = "required-border")
      : (debitBankProfitLedgerErr = "");
    !creditBankProfitLedger?.value
      ? (creditBankProfitLedgerErr = "required-border")
      : (creditBankProfitLedgerErr = "");
    // amount.trim() === '' ? amountErr = "Required" : amountErr = '';
    // headEmail.trim() === '' ? headEmailErr = "Required" : headEmailErr = '';

    // headPhone.trim() === '' ? headPhoneErr = "Required" : headPhoneErr = '';
    // branchHeadEmail.trim() === '' ? branchHEmailerr = "Required" : branchHEmailerr = '';
    // branchHeadMobile.trim() === '' ? BranchHPhoneErr = "Required" : BranchHPhoneErr = '';

    if (
      bankErr ||
      branchErr ||
      amcErr ||
      headPhoneErr ||
      branchHEmailerr ||
      BranchHPhoneErr ||
      fundErr ||
      ibanErr ||
      currencyErr ||
      txErr ||
      accErr ||
      accPurErr ||
      amountErr ||
      cityErr ||
      interestRateErr ||
      debitBankProfitLedgerErr ||
      creditBankProfitLedgerErr
    ) {
      setBankNameError(bankErr);
      setCityError(cityErr);
      //setHeadPhoneError(headPhoneErr);
      // setBranchHeadMobileError(BranchHPhoneErr);
      // setBranchHeadEmailError(branchHEmailerr);
      setBranchNameError(branchErr);
      setAmcError(amcErr);
      setFundError(fundErr);
      setCurrencyError(currencyErr);
      setTxNatureError(txErr);
      setAccTypeError(accErr);
      setIbanError(ibanErr);
      setAccPurposeError(accPurErr);
      setAmountError(amountErr);
      setHeadEmailError(headEmailErr);
      setInterestRateError(interestRateErr);
      setDebitBankProfitLedgerError(debitBankProfitLedgerErr);
      setCreditBankProfitLedgerError(creditBankProfitLedgerErr);
      return false;
    } else {
      return true;
    }
  };
  const EditAccountProcess = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);

      try {
        let temp = [...chartOfAccounts[0].family[3].sub_family];
        let gl_code = obj.gl_code || 0;

        if (txNature == "Current" || txNature == "PLS") {
          if (obj.gl_code) {
            let index = temp
              .find(
                (item) =>
                  item.name ==
                  (txNature == "Current" ? "CURRENT ACCOUNTS" : "PLS ACCOUNTS")
              )
              ?.gl?.findIndex((item) => item.code == obj.gl_code);

            let currentAccounts = temp.find(
              (item) =>
                item.name ==
                (txNature == "Current" ? "CURRENT ACCOUNTS" : "PLS ACCOUNTS")
            );
            currentAccounts.gl = [
              ...currentAccounts.gl.slice(0, index),
              {
                account_no: iban,
                account_title: accType,
                active: true,
                balance: currentBalance?.toString() || "0",
                bank: bankname,
                branch: branchName,
                code: gl_code,
                interest_rate: interestRate?.toString() || "0",
                related_ledgers: [
                  parseInt(debitBankProfitLedger?.value),
                  parseInt(creditBankProfitLedger?.value),
                ],
              },
              ...currentAccounts.gl.slice(index + 1),
            ];

            temp.find(
              (item) =>
                item.name ==
                (txNature == "Current" ? "CURRENT ACCOUNTS" : "PLS ACCOUNTS")
            ).gl = currentAccounts.gl;
          } else {
            let currentAccounts = temp.find(
              (item) =>
                item.name ==
                (txNature == "Current" ? "CURRENT ACCOUNTS" : "PLS ACCOUNTS")
            );
            gl_code =
              currentAccounts?.gl?.length > 0
                ? currentAccounts?.gl[currentAccounts?.gl.length - 1]?.code + 1
                : parseInt(`${currentAccounts.code}01`);
            currentAccounts.gl.push({
              account_no: iban,
              account_title: accType,
              active: true,
              balance: currentBalance?.toString() || "0",
              bank: bankname,
              branch: branchName,
              code: gl_code,
              interest_rate: interestRate?.toString() || "0",
              related_ledgers: [
                parseInt(debitBankProfitLedger?.value),
                parseInt(creditBankProfitLedger?.value),
              ],
            });
            temp.find(
              (item) =>
                item.name ==
                (txNature == "Current" ? "CURRENT ACCOUNTS" : "PLS ACCOUNTS")
            ).gl = currentAccounts.gl;
          }
        }
        const response = await EditAccount(
          email,
          amcCode,
          bankname,
          branchName,
          iban,
          fund,
          accType,
          txNature,
          currency,
          // parseFloat(amount || "0"),
          parseFloat(currentBalance || "0"),
          opHeadEmail,
          smaCheck,
          headPhone,
          branchHeadEmail,
          branchHeadMobile,
          folioNo,
          acc_code,
          city,
          txNature == "Current" || txNature == "PLS" ? gl_code : 0
        );
        if (response.data.status === 200) {
          sessionStorage.removeItem("accountObj");
          if (txNature == "Current" || txNature == "PLS") {
            let completeChartsofAccounts = [...chartOfAccounts];
            completeChartsofAccounts[0].family[3].sub_family = temp;
            await replaceChartOfAccountsFile(
              email,
              JSON.stringify({ main_family: completeChartsofAccounts }),
              amcCode
            );

            dispatch(GetChartOfAccount(completeChartsofAccounts));
          }
          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/bank-accounts`);
            setLoading(false);
          }, 2000);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);
        console.log("error", error);
        toast.error(
          error.response
            ? error.response.data.message?.toString()
            : error.message
            ? error.message
            : "Something went wrong"
        );
      }
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="input-holder">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-1 pointer"
                    onClick={() => {
                      sessionStorage.removeItem("accountObj");
                      history.replace("/admin/bank-accounts");
                    }}
                  ></i>
                  Edit - Bank Account
                </h4>
                <Link
                  to="/admin/bank-accounts"
                  className="btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Fund Detail</h4>
                    </div>
                    <div className="card-body">
                      {/* <div className="form-group  ">
                    <label>AMC Name</label>
                    <div className="  disable">
                      <input
                        type="text"
                        className="form-control "
                        style={{ opacity: '0.6' }}
                        value={amcName}
                        readOnly
                      />
                    </div>
                  </div> */}

                      <div className="form-group  ">
                        <label>
                          Fund Selection <span className="color-amber">*</span>
                        </label>
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <div data-tip="First Select Amc">
                          {fundLoading ? (
                            <div className="form-group">
                              <div className="ml-2 form-control">
                                Fund Loading
                                <i className="fa fa-spinner fa-spin fa-1x"></i>
                              </div>
                            </div>
                          ) : (
                            <select
                              className={`form-control  w-100 ${
                                fundError ? "required-border" : ""
                              }`}
                              value={fund}
                              onChange={(e) => {
                                setFundError("");
                                setFund(e.target.value);
                              }}
                            >
                              <option value="" defaultChecked hidden>
                                {" "}
                                Select Fund
                              </option>
                              {renderFundsDropdown()}
                            </select>
                          )}
                          {/* {fundError ? <p className="error-labels error-message">{fundError}</p> : ''} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Bank Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>
                          Bank Name <span className="color-amber">*</span>
                        </label>
                        <div>
                          <select
                            className={`form-control  w-100 ${
                              bankNameError ? "required-border" : ""
                            }`}
                            value={bankname}
                            onChange={(e) => {
                              setBankNameError("");
                              setBankName(e.target.value);
                              getBranchByName(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {" "}
                              Select Bank
                            </option>
                            {renderBankNameDropdown()}
                          </select>
                          {/* {bankNameError ? <p className="error-labels error-message2">{bankNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group  ">
                            <label>
                              Branch Name <span className="color-amber">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                branchNameError ? "required-border" : ""
                              }`}
                              value={branchName}
                              onChange={(e) => {
                                setBranchNameError("");
                                setBranchName(e.target.value);
                              }}
                            />
                            {/* <div>
                      <select
                        className={`form-control  w-100 ${
                          branchNameError ? 'required-border' : ''
                        }`}
                        value={branchName}
                        onChange={(e) => {
                          setBankNameError('');
                          setBranchName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {branchCheckFlag
                            ? 'No Branch Found'
                            : 'Select Branch Name'}
                        </option>
                        {renderBranchsDropdown()}
                      </select>
                     </div> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              City <span className="color-amber">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                cityError ? " required-border" : ""
                              }`}
                              value={city}
                              onChange={(e) => {
                                setCityError("");
                                setCity(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Special Managed Account</h4>
                    </div>
                    <div className="card-body">
                      <FormGroup check className="mt-4">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={smaCheck}
                            onChange={(e) => {
                              setSmaCheck(!smaCheck);
                            }}
                          />
                          <span className="form-check-sign" />
                          SMA (Optional)
                        </Label>
                      </FormGroup>

                      {smaCheck && (
                        <div className="form-group mt-4">
                          <label>Main Folio No</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={folioNo}
                              onChange={(e) => {
                                setFolioNo(e.target.value);
                                setFolioNoError("");
                              }}
                            />
                            {/* {folioNoError ? <p className="error-labels error-message">{folioNoError}</p> : ''} */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Contact Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>Operation Manager Email</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              headEmailError ? "" : ""
                            }`}
                            value={opHeadEmail}
                            onChange={(e) => {
                              setHeadEmail(e.target.value);
                              setHeadEmailError("");
                            }}
                          />
                          {/* {headEmailError ? <p className="error-labels error-message2">{headEmailError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Operation Manager Phone</label>
                        <div>
                          {/* <input
                        type="text"
                        className={`form-control  w-100 ${
                          headPhoneError ? 'required-border' : ''
                        }`}
                        value={headPhone}
                        onChange={(e) => {
                          setHeadPhone(e.target.value);
                          setHeadPhoneError('');
                        }}
                      /> */}
                          <PhoneInput
                            specialLabel=""
                            inputClass={`form-control  w-100 ${
                              headPhoneError ? "required-border" : ""
                            }`}
                            country={"pk"}
                            placeholder="+92 123-1234567"
                            value={headPhone}
                            onChange={(e) => {
                              setHeadPhone(e);
                              setHeadPhoneError("");
                            }}
                          />
                          {/* {headPhoneError ? <p className="error-labels error-message">{headPhoneError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Branch Manager Phone</label>
                        <div>
                          {/* <input
                        type="number"
                        className={`form-control  w-100 ${
                          branchHeadMobileError ? 'required-border' : ''
                        }`}
                        value={branchHeadMobile}
                        onChange={(e) => {
                          setBranchHeadMobile(e.target.value);
                          setBranchHeadMobileError('');
                        }}
                      /> */}
                          <PhoneInput
                            specialLabel=""
                            inputClass={`form-control  w-100 ${
                              branchHeadMobileError ? "" : ""
                            }`}
                            country={"pk"}
                            placeholder="+92 123-1234567"
                            value={branchHeadMobile}
                            onChange={(e) => {
                              setBranchHeadMobile(e);
                              setBranchHeadMobileError("");
                            }}
                          />
                          {/* {branchHeadMobileError ? <p className="error-labels error-message">{branchHeadMobileError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Branch Manager Email</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              branchHeadEmailError ? "" : ""
                            }`}
                            value={branchHeadEmail}
                            onChange={(e) => {
                              setBranchHeadEmail(e.target.value);
                              setBranchHeadEmailError("");
                            }}
                          />
                          {/* {branchHeadEmailError ? <p className="error-labels error-message2">{headEmailError}</p> : ''} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Account Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>
                          Bank Account Title{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              accTypeError ? "required-border" : ""
                            }`}
                            value={accType}
                            onChange={(e) => {
                              if (
                                e.target.value.match(
                                  accountTitle_AccountNumber_Regex
                                ) ||
                                e.target.value == ""
                              ) {
                                setAccType(e.target.value);
                                setAccTypeError("");
                              }
                            }}
                            disabled
                          />
                          {/* {accTypeError ? <p className="error-labels error-message2">{accTypeError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>
                          IBAN / Account No{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${
                              ibanError ? "required-border" : ""
                            }`}
                            value={iban}
                            // readOnly
                            onChange={(e) => {
                              if (
                                e.target.value.match(
                                  accountTitle_AccountNumber_Regex
                                ) ||
                                e.target.value == ""
                              ) {
                                setIban(e.target.value);
                                setIbanError("");
                              }
                            }}
                          />
                          {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>
                          Nature of Account{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={txNature}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Currency</label>
                        <div>
                          <select
                            className={`form-control  w-100 ${
                              currencyError ? "required-border" : ""
                            }`}
                            value={currency}
                            onChange={(e) => {
                              setCurrencyError("");
                              setCurrency(e.target.value);
                            }}
                          >
                            <option value="PKR" defaultChecked>
                              PKR
                            </option>
                            <option value="USD">USD</option>
                          </select>
                          {/* {currencyError ? <p className="error-labels error-message">{currencyError}</p> : ''} */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Current Balance</label>
                        <NumberFormat
                          thousandSeparator={true}
                          inputMode="numeric"
                          placeholder="Current Balance"
                          value={currentBalance}
                          className={`form-control w-100 `}
                          style={{ textAlign: "right" }}
                          onValueChange={(e) => {
                            setCurrentBalance(e.value);
                          }}
                        />
                      </div>

                      <div
                        className="form-group"
                        data-tip="Interest rate for Bank Profit"
                      >
                        <label>
                          Interest Rate % <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          placeholder="Interest rate to calculate bank profit"
                          value={interestRate}
                          className={`form-control w-100 ${interestRateError}`}
                          style={{ textAlign: "right" }}
                          onChange={(e) => {
                            setInterestRateError(false);
                            setInterestRate(e.target.value);
                          }}
                        />
                      </div>

                      <div
                        className="form-group"
                        data-tip="Ledger Codes to Debit/Credit in case of bank profit"
                      >
                        <div>
                          Associated Ledger Codes{" "}
                          <span className="color-amber">*</span>
                        </div>

                        {ledgerOption.length > 1 ? (
                          <Autocomplete
                            className={`mt-2 autocomplete ${debitBankProfitLedgerError}`}
                            data-tip="Ledger Code to associate in case of Expense"
                            disablePortal
                            id="combo-box-demo"
                            options={ledgerOption}
                            style={{ width: "100%" }}
                            PaperComponent={({ children }) => (
                              <Paper
                                style={{
                                  background: context.theme ? "" : "#1E1E2F",
                                  color: "white",
                                }}
                              >
                                {children}
                              </Paper>
                            )}
                            sx={{
                              "& .MuiAutocomplete-input": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                              "& .MuiInputLabel-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },

                              "& .MuiIconButton-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                            }}
                            value={debitBankProfitLedger?.label || ""}
                            onChange={(event: any, newValue: string | null) => {
                              setDebitBankProfitLedger(newValue);
                              setDebitBankProfitLedgerError(false);
                            }}
                            inputValue={
                              debitBankProfitLedger?.label ||
                              debitBankProfitLedger ||
                              ""
                            }
                            onInputChange={(event, newInputValue, reason) => {
                              if (reason == "input") {
                                setDebitBankProfitLedgerError(false);
                                setDebitBankProfitLedger(newInputValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Ledger for debit"
                                variant="standard"
                              />
                            )}
                          />
                        ) : (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        )}

                        {ledgerOption.length > 1 ? (
                          <Autocomplete
                            className={`mt-2 autocomplete ${creditBankProfitLedgerError}`}
                            data-tip="Ledger Code to associate in case of Expense"
                            disablePortal
                            id="combo-box-demo"
                            options={ledgerOption}
                            style={{ width: "100%" }}
                            PaperComponent={({ children }) => (
                              <Paper
                                style={{
                                  background: context.theme ? "" : "#1E1E2F",
                                  color: "white",
                                }}
                              >
                                {children}
                              </Paper>
                            )}
                            sx={{
                              "& .MuiAutocomplete-input": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                              "& .MuiInputLabel-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },

                              "& .MuiIconButton-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                            }}
                            value={creditBankProfitLedger?.label || ""}
                            onChange={(event: any, newValue: string | null) => {
                              setCreditBankProfitLedger(newValue);
                              setCreditBankProfitLedgerError(false);
                            }}
                            inputValue={
                              creditBankProfitLedger?.label ||
                              creditBankProfitLedger ||
                              ""
                            }
                            onInputChange={(event, newInputValue, reason) => {
                              if (reason == "input") {
                                setCreditBankProfitLedgerError(false);
                                setCreditBankProfitLedger(newInputValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Ledger for credit"
                                variant="standard"
                              />
                            )}
                          />
                        ) : (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        )}
                      </div>

                      {/* <div className="form-group  ">
                    <label>Amount</label>
                   
                    <div>
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                    
                        className={`form-control  w-100 text-right ${
                          amountError ? 'required-border' : ''
                        }`}
                        value={amount}
                        onValueChange={(e) => {
                          setAmount(e.value);
                          setAmountError('');
                        }}
                      />
                     
                    </div>
                  </div> */}
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="d-flex ">
                <div className="">
                  <button
                    className="btn btn-primary mr-2"
                    onClick={EditAccountProcess}
                    disabled={Boolean(Loading)}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>

                <div className=" ">
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      sessionStorage.removeItem("accountObj");
                      history.replace("/admin/bank-accounts");
                    }}
                    disabled={Loading}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditAccountComponent;
