import { useState, useContext, useEffect } from "react";
// import { Container, Row, Col } from "reactstrap";
// import { toast, ToastContainer } from "react-toastify";
// import { getFamilyInfoForLedgerCode } from "../../../../stores/services/chatofaccounts.service";
import ReactTooltip from "react-tooltip";
import { FiRefreshCcw } from "react-icons/fi";
import Switch from "@mui/material/Switch";

import { ThemeContext } from "../../../../contexts/ThemeContext";
import { set } from "mobx";

const RowComponent = (props) => {
  const {
    item,
    gap,
    statusModal,
    setStatusModal,
    changeStatusLedgerCode,
    setChangeStatusLedgerCode,
    editLedgerCode,
    setEditLedgerCode,
    plusIcon,
    viewAddModal,
    setViewAddModal,
    addModalParentLedgerCode,
    setAddModalParentLedgerCode,
    addModalParentLedgerName,
    setAddModalParentLedgerName,
    setBankAccountCardExpanded,
    setExpenseCardExpanded,
    setBankProfitCardExpanded,
    setFeeSlabsCardExpanded,
    viewEditModal,
    setViewEditModal,
    setViewOnly,
    isExpanded,
    setIsExpanded,
  } = props;

  let {
    parentName,
    mainFamilyCode,
    setMainFamilyCode,
    mainFamilyName,
    setMainFamilyName,
    familyCode,
    setFamilyCode,
    familyName,
    setFamilyName,
    subFamilyCode,
    setSubFamilyCode,
    subFamilyName,
    setSubFamilyName,
    childFamilyCode,
    setChildFamilyCode,
    childFamilyName,
    setChildFamilyName,
    mainFamilyFlag,
    setMainFamilyFlag,
    familyFlag,
    setFamilyFlag,
    subFamilyFlag,
    setSubFamilyFlag,
    childFamilyFlag,
    setChildFamilyFlag,
    glFlag,
    setGlFlag,
    glCode,
    setGlCode,
    glName,
    setGlName,
    fundsData,
    setFundsData,
    currentBalance,
    setCurrentBalance,
    associatedAccounts,
    setAssociatedAccounts,
    relatedLedger,
    setRelatedLedger,
    slabData,
    setSlabData,
    bankAccountNumber,
    setBankAccountNumber,
    bankAccountTitle,
    setBankAccountTitle,
    bankName,
    setBankName,
    bankAccountBranch,
    setBankAccountBranch,
    interestRate,
    setInterestRate,
    annualPayment,
    setAnnualPayment,
    dailyExpense,
    setDailyExpense,
    halfYearlyFee,
    setHalfYearlyFee,
    quarterlyFee,
    setQuarterlyFee,
    yearlyFee,
    setYearlyFee,
    debitBankProfitLedger,
    setDebitBankProfitLedger,
    creditBankProfitLedger,
    setCreditBankProfitLedger,
    associatedLedgerExpense,
    setAssociatedLedgerExpense,
    bankAccountFund,
    setBankAccountFund,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState(false);
  const context = useContext(ThemeContext);

  let gaping = "0rem";

  if (item.family) {
    gaping = "1rem";
    mainFamilyCode = item.code;
    mainFamilyName = item.name;
    mainFamilyFlag = true;
    familyFlag = false;
    subFamilyFlag = false;
    childFamilyFlag = false;
  } else if (item.sub_family) {
    gaping = "1.5rem";
    familyCode = item.code;
    familyName = item.name;
    mainFamilyFlag = true;
    familyFlag = true;
    subFamilyFlag = false;
    childFamilyFlag = false;
  } else if (item.child_family) {
    gaping = "2rem";
    subFamilyCode = item.code;
    subFamilyName = item.name;
    mainFamilyFlag = true;
    familyFlag = true;
    subFamilyFlag = true;
    childFamilyFlag = false;
  } else if (item.gl && subFamilyCode) {
    gaping = "2.5rem";
    childFamilyCode = item.code;
    childFamilyName = item.name;
    mainFamilyFlag = true;
    familyFlag = true;
    subFamilyFlag = true;
    childFamilyFlag = true;
  } else if (item.gl) {
    gaping = "2.5rem";
    subFamilyCode = item.code;
    subFamilyName = item.name;
    mainFamilyFlag = true;
    familyFlag = true;
    subFamilyFlag = true;
    childFamilyFlag = false;
  } else {
    mainFamilyFlag = true;
    familyFlag = true;
    subFamilyFlag = true;
    childFamilyFlag = true;
    glFlag = true;
    glCode = item.code;
    glName = item.name;

    if (item.associated_ledgers) {
      if (item.associated_ledgers.length > 1) {
        item.associated_ledgers.map((tem, index) => {
          if (Number(String(tem)[0]) === 1) {
            debitBankProfitLedger = item;
          } else {
            creditBankProfitLedger = item;
          }
        });
      } else {
        if (item.daily_expense) {
          dailyExpense = item.daily_expense;
          associatedLedgerExpense = item.associated_ledgers[0];
        }
      }
    }
    if (item.related_ledgers) {
      if (item.related_ledgers.length > 1) {
        item.related_ledgers.map((tem, index) => {
          // if (Number(String(tem)[0]) === 1) {
          //   debitBankProfitLedger = tem;

          // } else {
          //   creditBankProfitLedger = tem;
          // }
          if (index == 0) {
            debitBankProfitLedger = tem;
          } else if (index == 1) {
            creditBankProfitLedger = tem;
          }
        });
      }
    }
    if (item.related_ledgers_slabs) {
      relatedLedger = item.related_ledgers_slabs[0];
    }
    if (item.balance) {
      currentBalance = parseInt(item.balance);
    }
    if (item.slabs) {
      slabData = item.slabs;
    }
    if (item.account_no) {
      bankAccountNumber = item.account_no;
    }
    if (item.account_title) {
      bankAccountTitle = item.account_title;
    }
    if (item.bank) {
      bankName = item.bank;
    }
    if (item.branch) {
      bankAccountBranch = item.branch;
    }
    if (item.interest_rate) {
      interestRate = item.interest_rate;
    }
    if (item.annual_payment) {
      annualPayment = item.annualPayment;
    }
    if (item.quarterly_fee) {
      quarterlyFee = item.quarterly_fee;
    }
    if (item.half_yearly_fee) {
      halfYearlyFee = item.half_yearly_fee;
    }
    if (item.yearly_fee) {
      yearlyFee = item.yearly_fee;
    }
    if (item?.funds) {
      fundsData = item.funds;
    }
  }

  useEffect(() => {
    if (isExpanded == false) {
      setExpanded(false);
    }
  }, [isExpanded]);

  const handleEnter = () => {
    setHover(true);
  };

  const handleLeave = () => {
    setHover(false);
  };
  return (
    <>
      <ReactTooltip textColor="white" backgroundColor="black" effect="float" />
      <tr
        onMouseEnter={() => handleEnter()}
        onMouseLeave={() => handleLeave()}
        style={
          hover
            ? { backgroundColor: context.theme ? "" : "rgba(30,30,46,1)" }
            : { backgroundColor: "inherit" }
        }
      >
        <td style={{ paddingLeft: gap }}>
          {(
            (
              item.family
                ? true
                : item.sub_family
                ? true
                : item.child_family
                ? true
                : item.gl
                ? true
                : false
            )
              ? true
              : false
          ) ? (
            expanded ? (
              <i
                className="tim-icons icon-simple-delete mx-1"
                onClick={() => setExpanded(false)}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <i
                className="tim-icons icon-simple-add mx-1"
                onClick={() => {
                  setExpanded(true);
                  setIsExpanded(true);
                }}
                style={{ cursor: "pointer" }}
              />
            )
          ) : null}
        </td>
        <td style={{ paddingLeft: gap }}>{item.code}</td>
        <td style={{ paddingLeft: gap }}>
          {item.name ? item.name : item.bank}
        </td>
        <td style={{ paddingLeft: gap }}>
          {item.active ? (
            <span className="text-success">Active</span>
          ) : (
            <span className="text-danger">Deactive</span>
          )}
        </td>

        <td className="d-flex">
          <div className="mx-2">
            <i
              className="fa fa-eye"
              data-tip={`View ${item.name}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditLedgerCode(item.code);
                setMainFamilyCode(mainFamilyCode);
                setMainFamilyName(mainFamilyName);
                setFamilyCode(familyCode);
                setFamilyName(familyName);
                setSubFamilyCode(subFamilyCode);
                setSubFamilyName(subFamilyName);
                setChildFamilyCode(childFamilyCode);
                setChildFamilyName(childFamilyName);
                setMainFamilyFlag(mainFamilyFlag);
                setFamilyFlag(familyFlag);
                setSubFamilyFlag(subFamilyFlag);
                setChildFamilyFlag(childFamilyFlag);
                setGlCode(glCode);
                setGlName(glName);
                setGlFlag(glFlag);
                setDailyExpense(dailyExpense);
                setDebitBankProfitLedger(debitBankProfitLedger);
                setCreditBankProfitLedger(creditBankProfitLedger);
                setAssociatedLedgerExpense(associatedLedgerExpense);
                setRelatedLedger(relatedLedger);
                setCurrentBalance(currentBalance);
                setSlabData(slabData);
                setFundsData(fundsData);
                setBankAccountNumber(bankAccountNumber);
                setBankAccountTitle(bankAccountTitle);
                setBankName(bankName);
                setBankAccountBranch(bankAccountBranch);
                setInterestRate(interestRate);
                setAnnualPayment(annualPayment);
                setQuarterlyFee(quarterlyFee);
                setHalfYearlyFee(halfYearlyFee);
                setYearlyFee(yearlyFee);
                setBankAccountFund(bankAccountFund);
                setBankAccountCardExpanded(false);
                setExpenseCardExpanded(false);
                setBankProfitCardExpanded(false);
                setFeeSlabsCardExpanded(false);
                setViewEditModal(true);
                setViewOnly(true);
              }}
            ></i>
          </div>

          <div className="mx-2">
            <i
              className="tim-icons icon-pencil"
              data-tip={`Edit ${item.name}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditLedgerCode(item.code);
                setMainFamilyCode(mainFamilyCode);
                setMainFamilyName(mainFamilyName);
                setFamilyCode(familyCode);
                setFamilyName(familyName);
                setSubFamilyCode(subFamilyCode);
                setSubFamilyName(subFamilyName);
                setChildFamilyCode(childFamilyCode);
                setChildFamilyName(childFamilyName);
                setMainFamilyFlag(mainFamilyFlag);
                setFamilyFlag(familyFlag);
                setSubFamilyFlag(subFamilyFlag);
                setChildFamilyFlag(childFamilyFlag);
                setGlCode(glCode);
                setGlName(glName);
                setGlFlag(glFlag);
                setDailyExpense(dailyExpense);
                setDebitBankProfitLedger(debitBankProfitLedger);
                setCreditBankProfitLedger(creditBankProfitLedger);
                setAssociatedLedgerExpense(associatedLedgerExpense);
                setRelatedLedger(relatedLedger);
                setCurrentBalance(currentBalance);
                setSlabData(slabData);
                setFundsData(fundsData);
                setBankAccountNumber(bankAccountNumber);
                setBankAccountTitle(bankAccountTitle);
                setBankName(bankName);
                setBankAccountBranch(bankAccountBranch);
                setInterestRate(interestRate);
                setAnnualPayment(annualPayment);
                setQuarterlyFee(quarterlyFee);
                setHalfYearlyFee(halfYearlyFee);
                setYearlyFee(yearlyFee);
                setBankAccountFund(bankAccountFund);
                setBankAccountCardExpanded(false);
                setExpenseCardExpanded(false);
                setBankProfitCardExpanded(false);
                setFeeSlabsCardExpanded(false);
                setViewEditModal(true);
              }}
            ></i>
          </div>

          {plusIcon ? (
            <div className="mr-1">
              <ReactTooltip
                textColor="white"
                backgroundColor="black"
                effect="float"
              />
              <i
                className="tim-icons icon-simple-add text-primary"
                data-tip={`Add New Child to ${item.name}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMainFamilyCode(mainFamilyCode);
                  setMainFamilyName(mainFamilyName);
                  setFamilyCode(familyCode);
                  setFamilyName(familyName);
                  setSubFamilyCode(subFamilyCode);
                  setSubFamilyName(subFamilyName);
                  setChildFamilyCode(childFamilyCode);
                  setChildFamilyName(childFamilyName);
                  setMainFamilyFlag(mainFamilyFlag);
                  setFamilyFlag(familyFlag);
                  setSubFamilyFlag(subFamilyFlag);
                  setChildFamilyFlag(childFamilyFlag);
                  setBankAccountFund(bankAccountFund);
                  setBankAccountCardExpanded(false);
                  setExpenseCardExpanded(false);
                  setBankProfitCardExpanded(false);
                  setFeeSlabsCardExpanded(false);
                  setAddModalParentLedgerCode(item.code);
                  setAddModalParentLedgerName(item.name);
                  setViewAddModal(true);
                }}
              ></i>
            </div>
          ) : null}

          <ReactTooltip
            textColor="white"
            backgroundColor="black"
            effect="float"
          />
          <div
            className="mx-2"
            data-tip={
              item.active
                ? `Mark ${item.name} as Inactive`
                : `Mark ${item.name} as Active`
            }
            onClick={() => {
              setChangeStatusLedgerCode(item.code);
              setMainFamilyCode(mainFamilyCode);
              setMainFamilyName(mainFamilyName);
              setFamilyCode(familyCode);
              setFamilyName(familyName);
              setSubFamilyCode(subFamilyCode);
              setSubFamilyName(subFamilyName);
              setChildFamilyCode(childFamilyCode);
              setChildFamilyName(childFamilyName);
              setMainFamilyFlag(mainFamilyFlag);
              setFamilyFlag(familyFlag);
              setSubFamilyFlag(subFamilyFlag);
              setChildFamilyFlag(childFamilyFlag);
              setGlCode(glCode);
              setGlName(glName);
              setGlFlag(glFlag);
              setDailyExpense(dailyExpense);
              setDebitBankProfitLedger(debitBankProfitLedger);
              setCreditBankProfitLedger(creditBankProfitLedger);
              setAssociatedLedgerExpense(associatedLedgerExpense);
              setRelatedLedger(relatedLedger);
              setCurrentBalance(currentBalance);
              setSlabData(slabData);
              setFundsData(fundsData);
              setBankAccountNumber(bankAccountNumber);
              setBankAccountTitle(bankAccountTitle);
              setBankName(bankName);
              setBankAccountBranch(bankAccountBranch);
              setBankAccountFund(bankAccountFund);
              setInterestRate(interestRate);
              setAnnualPayment(annualPayment);
              setQuarterlyFee(quarterlyFee);
              setHalfYearlyFee(halfYearlyFee);
              setYearlyFee(yearlyFee);
              setBankAccountCardExpanded(false);
              setExpenseCardExpanded(false);
              setBankProfitCardExpanded(false);
              setFeeSlabsCardExpanded(false);
              setStatusModal(true);
            }}
          >
            {/* {item.active ? <FiRefreshCcw className="text-danger" data-tip={`Mark ${item.name} as Inactive`}
              style={{ cursor: "pointer" }}/> : <FiRefreshCcw className="text-success" data-tip={`Mark ${item.name} as Active`}
              style={{ cursor: "pointer" }}/>} */}
            {/* <i
              // add a check to see current status of item and display icon accordingly
              className="tim-icons icon-trash-simple text-danger"
              data-tip={`Mark ${item.name} as Inactive`}
              style={{ cursor: "pointer" }}
            ></i> */}
            <Switch checked={item.active} color="success" size="small" />
          </div>
        </td>
      </tr>

      {expanded ? (
        item.family ? (
          item.family.map((item, index) => {
            return (
              <>
                <RowComponent
                  gap={gaping}
                  item={item}
                  statusModal={statusModal}
                  setStatusModal={setStatusModal}
                  changeStatusLedgerCode={changeStatusLedgerCode}
                  setChangeStatusLedgerCode={setChangeStatusLedgerCode}
                  editLedgerCode={editLedgerCode}
                  setEditLedgerCode={setEditLedgerCode}
                  plusIcon={plusIcon}
                  viewAddModal={setViewAddModal}
                  setViewAddModal={setViewAddModal}
                  addModalParentLedgerCode={addModalParentLedgerCode}
                  setAddModalParentLedgerCode={setAddModalParentLedgerCode}
                  addModalParentLedgerName={addModalParentLedgerName}
                  setAddModalParentLedgerName={setAddModalParentLedgerName}
                  setBankAccountCardExpanded={setBankAccountCardExpanded}
                  setExpenseCardExpanded={setExpenseCardExpanded}
                  setBankProfitCardExpanded={setBankProfitCardExpanded}
                  setFeeSlabsCardExpanded={setFeeSlabsCardExpanded}
                  parentName={parentName}
                  mainFamilyCode={mainFamilyCode}
                  setMainFamilyCode={setMainFamilyCode}
                  mainFamilyName={mainFamilyName}
                  setMainFamilyName={setMainFamilyName}
                  familyCode={familyCode}
                  setFamilyCode={setFamilyCode}
                  familyName={familyName}
                  setFamilyName={setFamilyName}
                  subFamilyCode={subFamilyCode}
                  setSubFamilyCode={setSubFamilyCode}
                  subFamilyName={subFamilyName}
                  setSubFamilyName={setSubFamilyName}
                  childFamilyCode={childFamilyCode}
                  setChildFamilyCode={setChildFamilyCode}
                  childFamilyName={childFamilyName}
                  setChildFamilyName={setChildFamilyName}
                  mainFamilyFlag={mainFamilyFlag}
                  setMainFamilyFlag={setMainFamilyFlag}
                  familyFlag={familyFlag}
                  setFamilyFlag={setFamilyFlag}
                  subFamilyFlag={subFamilyFlag}
                  setSubFamilyFlag={setSubFamilyFlag}
                  childFamilyFlag={childFamilyFlag}
                  setChildFamilyFlag={setChildFamilyFlag}
                  glFlag={glFlag}
                  setGlFlag={setGlFlag}
                  viewEditModal={viewEditModal}
                  setViewEditModal={setViewEditModal}
                  setViewOnly={setViewOnly}
                  glCode={glCode}
                  setGlCode={setGlCode}
                  glName={glName}
                  setGlName={setGlName}
                  currentBalance={currentBalance}
                  setCurrentBalance={setCurrentBalance}
                  associatedAccounts={associatedAccounts}
                  setAssociatedAccounts={setAssociatedAccounts}
                  relatedLedger={relatedLedger}
                  setRelatedLedger={setRelatedLedger}
                  slabData={slabData}
                  setSlabData={setSlabData}
                  fundsData={fundsData}
                  setFundsData={setFundsData}
                  bankAccountNumber={bankAccountNumber}
                  setBankAccountNumber={setBankAccountNumber}
                  bankAccountTitle={bankAccountTitle}
                  setBankAccountTitle={setBankAccountTitle}
                  bankName={bankName}
                  setBankName={setBankName}
                  bankAccountBranch={bankAccountBranch}
                  setBankAccountBranch={setBankAccountBranch}
                  interestRate={interestRate}
                  setInterestRate={setInterestRate}
                  annualPayment={annualPayment}
                  setAnnualPayment={setAnnualPayment}
                  dailyExpense={dailyExpense}
                  setDailyExpense={setDailyExpense}
                  halfYearlyFee={halfYearlyFee}
                  setHalfYearlyFee={setHalfYearlyFee}
                  quarterlyFee={quarterlyFee}
                  setQuarterlyFee={setQuarterlyFee}
                  yearlyFee={yearlyFee}
                  setYearlyFee={setYearlyFee}
                  debitBankProfitLedger={debitBankProfitLedger}
                  setDebitBankProfitLedger={setDebitBankProfitLedger}
                  creditBankProfitLedger={creditBankProfitLedger}
                  setCreditBankProfitLedger={setCreditBankProfitLedger}
                  associatedLedgerExpense={associatedLedgerExpense}
                  setAssociatedLedgerExpense={setAssociatedLedgerExpense}
                  bankAccountFund={bankAccountFund}
                  setBankAccountFund={setBankAccountFund}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              </>
            );
          })
        ) : item.sub_family ? (
          item.sub_family.map((item, index) => {
            return (
              <>
                <RowComponent
                  gap={gaping}
                  item={item}
                  statusModal={statusModal}
                  setStatusModal={setStatusModal}
                  changeStatusLedgerCode={changeStatusLedgerCode}
                  setChangeStatusLedgerCode={setChangeStatusLedgerCode}
                  editLedgerCode={editLedgerCode}
                  setEditLedgerCode={setEditLedgerCode}
                  plusIcon={plusIcon}
                  viewAddModal={setViewAddModal}
                  setViewAddModal={setViewAddModal}
                  addModalParentLedgerCode={addModalParentLedgerCode}
                  setAddModalParentLedgerCode={setAddModalParentLedgerCode}
                  addModalParentLedgerName={addModalParentLedgerName}
                  setAddModalParentLedgerName={setAddModalParentLedgerName}
                  setBankAccountCardExpanded={setBankAccountCardExpanded}
                  setExpenseCardExpanded={setExpenseCardExpanded}
                  setBankProfitCardExpanded={setBankProfitCardExpanded}
                  setFeeSlabsCardExpanded={setFeeSlabsCardExpanded}
                  parentName={parentName}
                  mainFamilyCode={mainFamilyCode}
                  setMainFamilyCode={setMainFamilyCode}
                  mainFamilyName={mainFamilyName}
                  setMainFamilyName={setMainFamilyName}
                  familyCode={familyCode}
                  setFamilyCode={setFamilyCode}
                  familyName={familyName}
                  setFamilyName={setFamilyName}
                  subFamilyCode={subFamilyCode}
                  setSubFamilyCode={setSubFamilyCode}
                  subFamilyName={subFamilyName}
                  setSubFamilyName={setSubFamilyName}
                  childFamilyCode={childFamilyCode}
                  setChildFamilyCode={setChildFamilyCode}
                  childFamilyName={childFamilyName}
                  setChildFamilyName={setChildFamilyName}
                  mainFamilyFlag={mainFamilyFlag}
                  setMainFamilyFlag={setMainFamilyFlag}
                  familyFlag={familyFlag}
                  setFamilyFlag={setFamilyFlag}
                  subFamilyFlag={subFamilyFlag}
                  setSubFamilyFlag={setSubFamilyFlag}
                  childFamilyFlag={childFamilyFlag}
                  setChildFamilyFlag={setChildFamilyFlag}
                  glFlag={glFlag}
                  setGlFlag={setGlFlag}
                  viewEditModal={viewEditModal}
                  setViewEditModal={setViewEditModal}
                  setViewOnly={setViewOnly}
                  glCode={glCode}
                  setGlCode={setGlCode}
                  glName={glName}
                  setGlName={setGlName}
                  currentBalance={currentBalance}
                  setCurrentBalance={setCurrentBalance}
                  associatedAccounts={associatedAccounts}
                  setAssociatedAccounts={setAssociatedAccounts}
                  relatedLedger={relatedLedger}
                  setRelatedLedger={setRelatedLedger}
                  slabData={slabData}
                  setSlabData={setSlabData}
                  fundsData={fundsData}
                  setFundsData={setFundsData}
                  bankAccountNumber={bankAccountNumber}
                  setBankAccountNumber={setBankAccountNumber}
                  bankAccountTitle={bankAccountTitle}
                  setBankAccountTitle={setBankAccountTitle}
                  bankName={bankName}
                  setBankName={setBankName}
                  bankAccountBranch={bankAccountBranch}
                  setBankAccountBranch={setBankAccountBranch}
                  interestRate={interestRate}
                  setInterestRate={setInterestRate}
                  annualPayment={annualPayment}
                  setAnnualPayment={setAnnualPayment}
                  dailyExpense={dailyExpense}
                  setDailyExpense={setDailyExpense}
                  halfYearlyFee={halfYearlyFee}
                  setHalfYearlyFee={setHalfYearlyFee}
                  quarterlyFee={quarterlyFee}
                  setQuarterlyFee={setQuarterlyFee}
                  yearlyFee={yearlyFee}
                  setYearlyFee={setYearlyFee}
                  debitBankProfitLedger={debitBankProfitLedger}
                  setDebitBankProfitLedger={setDebitBankProfitLedger}
                  creditBankProfitLedger={creditBankProfitLedger}
                  setCreditBankProfitLedger={setCreditBankProfitLedger}
                  associatedLedgerExpense={associatedLedgerExpense}
                  setAssociatedLedgerExpense={setAssociatedLedgerExpense}
                  bankAccountFund={bankAccountFund}
                  setBankAccountFund={setBankAccountFund}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              </>
            );
          })
        ) : item.child_family && item.gl ? (
          <>
            {item.child_family.map((item, index) => {
              return (
                <>
                  <RowComponent
                    gap={gaping}
                    item={item}
                    statusModal={statusModal}
                    setStatusModal={setStatusModal}
                    changeStatusLedgerCode={changeStatusLedgerCode}
                    setChangeStatusLedgerCode={setChangeStatusLedgerCode}
                    editLedgerCode={editLedgerCode}
                    setEditLedgerCode={setEditLedgerCode}
                    plusIcon={plusIcon}
                    viewAddModal={setViewAddModal}
                    setViewAddModal={setViewAddModal}
                    addModalParentLedgerCode={addModalParentLedgerCode}
                    setAddModalParentLedgerCode={setAddModalParentLedgerCode}
                    addModalParentLedgerName={addModalParentLedgerName}
                    setAddModalParentLedgerName={setAddModalParentLedgerName}
                    setBankAccountCardExpanded={setBankAccountCardExpanded}
                    setExpenseCardExpanded={setExpenseCardExpanded}
                    setBankProfitCardExpanded={setBankProfitCardExpanded}
                    setFeeSlabsCardExpanded={setFeeSlabsCardExpanded}
                    parentName={parentName}
                    mainFamilyCode={mainFamilyCode}
                    setMainFamilyCode={setMainFamilyCode}
                    mainFamilyName={mainFamilyName}
                    setMainFamilyName={setMainFamilyName}
                    familyCode={familyCode}
                    setFamilyCode={setFamilyCode}
                    familyName={familyName}
                    setFamilyName={setFamilyName}
                    subFamilyCode={subFamilyCode}
                    setSubFamilyCode={setSubFamilyCode}
                    subFamilyName={subFamilyName}
                    setSubFamilyName={setSubFamilyName}
                    childFamilyCode={childFamilyCode}
                    setChildFamilyCode={setChildFamilyCode}
                    childFamilyName={childFamilyName}
                    setChildFamilyName={setChildFamilyName}
                    mainFamilyFlag={mainFamilyFlag}
                    setMainFamilyFlag={setMainFamilyFlag}
                    familyFlag={familyFlag}
                    setFamilyFlag={setFamilyFlag}
                    subFamilyFlag={subFamilyFlag}
                    setSubFamilyFlag={setSubFamilyFlag}
                    childFamilyFlag={childFamilyFlag}
                    setChildFamilyFlag={setChildFamilyFlag}
                    glFlag={glFlag}
                    setGlFlag={setGlFlag}
                    viewEditModal={viewEditModal}
                    setViewEditModal={setViewEditModal}
                    setViewOnly={setViewOnly}
                    glCode={glCode}
                    setGlCode={setGlCode}
                    glName={glName}
                    setGlName={setGlName}
                    currentBalance={currentBalance}
                    setCurrentBalance={setCurrentBalance}
                    associatedAccounts={associatedAccounts}
                    setAssociatedAccounts={setAssociatedAccounts}
                    relatedLedger={relatedLedger}
                    setRelatedLedger={setRelatedLedger}
                    slabData={slabData}
                    setSlabData={setSlabData}
                    fundsData={fundsData}
                    setFundsData={setFundsData}
                    bankAccountNumber={bankAccountNumber}
                    setBankAccountNumber={setBankAccountNumber}
                    bankAccountTitle={bankAccountTitle}
                    setBankAccountTitle={setBankAccountTitle}
                    bankName={bankName}
                    setBankName={setBankName}
                    bankAccountBranch={bankAccountBranch}
                    setBankAccountBranch={setBankAccountBranch}
                    interestRate={interestRate}
                    setInterestRate={setInterestRate}
                    annualPayment={annualPayment}
                    setAnnualPayment={setAnnualPayment}
                    dailyExpense={dailyExpense}
                    setDailyExpense={setDailyExpense}
                    halfYearlyFee={halfYearlyFee}
                    setHalfYearlyFee={setHalfYearlyFee}
                    quarterlyFee={quarterlyFee}
                    setQuarterlyFee={setQuarterlyFee}
                    yearlyFee={yearlyFee}
                    setYearlyFee={setYearlyFee}
                    debitBankProfitLedger={debitBankProfitLedger}
                    setDebitBankProfitLedger={setDebitBankProfitLedger}
                    creditBankProfitLedger={creditBankProfitLedger}
                    setCreditBankProfitLedger={setCreditBankProfitLedger}
                    associatedLedgerExpense={associatedLedgerExpense}
                    setAssociatedLedgerExpense={setAssociatedLedgerExpense}
                    bankAccountFund={bankAccountFund}
                    setBankAccountFund={setBankAccountFund}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                  />
                </>
              );
            })}
            {item.gl.map((item, index) => {
              return (
                <>
                  <RowComponent
                    gap={gaping}
                    item={item}
                    statusModal={statusModal}
                    setStatusModal={setStatusModal}
                    changeStatusLedgerCode={changeStatusLedgerCode}
                    setChangeStatusLedgerCode={setChangeStatusLedgerCode}
                    editLedgerCode={editLedgerCode}
                    setEditLedgerCode={setEditLedgerCode}
                    plusIcon={false}
                    viewAddModal={setViewAddModal}
                    setViewAddModal={setViewAddModal}
                    addModalParentLedgerCode={addModalParentLedgerCode}
                    setAddModalParentLedgerCode={setAddModalParentLedgerCode}
                    addModalParentLedgerName={addModalParentLedgerName}
                    setAddModalParentLedgerName={setAddModalParentLedgerName}
                    setBankAccountCardExpanded={setBankAccountCardExpanded}
                    setExpenseCardExpanded={setExpenseCardExpanded}
                    setBankProfitCardExpanded={setBankProfitCardExpanded}
                    setFeeSlabsCardExpanded={setFeeSlabsCardExpanded}
                    parentName={parentName}
                    mainFamilyCode={mainFamilyCode}
                    setMainFamilyCode={setMainFamilyCode}
                    mainFamilyName={mainFamilyName}
                    setMainFamilyName={setMainFamilyName}
                    familyCode={familyCode}
                    setFamilyCode={setFamilyCode}
                    familyName={familyName}
                    setFamilyName={setFamilyName}
                    subFamilyCode={subFamilyCode}
                    setSubFamilyCode={setSubFamilyCode}
                    subFamilyName={subFamilyName}
                    setSubFamilyName={setSubFamilyName}
                    childFamilyCode={childFamilyCode}
                    setChildFamilyCode={setChildFamilyCode}
                    childFamilyName={childFamilyName}
                    setChildFamilyName={setChildFamilyName}
                    mainFamilyFlag={mainFamilyFlag}
                    setMainFamilyFlag={setMainFamilyFlag}
                    familyFlag={familyFlag}
                    setFamilyFlag={setFamilyFlag}
                    subFamilyFlag={subFamilyFlag}
                    setSubFamilyFlag={setSubFamilyFlag}
                    childFamilyFlag={childFamilyFlag}
                    setChildFamilyFlag={setChildFamilyFlag}
                    glFlag={glFlag}
                    setGlFlag={setGlFlag}
                    viewEditModal={viewEditModal}
                    setViewEditModal={setViewEditModal}
                    setViewOnly={setViewOnly}
                    glCode={glCode}
                    setGlCode={setGlCode}
                    glName={glName}
                    setGlName={setGlName}
                    currentBalance={currentBalance}
                    setCurrentBalance={setCurrentBalance}
                    associatedAccounts={associatedAccounts}
                    setAssociatedAccounts={setAssociatedAccounts}
                    relatedLedger={relatedLedger}
                    setRelatedLedger={setRelatedLedger}
                    slabData={slabData}
                    setSlabData={setSlabData}
                    fundsData={fundsData}
                    setFundsData={setFundsData}
                    bankAccountNumber={bankAccountNumber}
                    setBankAccountNumber={setBankAccountNumber}
                    bankAccountTitle={bankAccountTitle}
                    setBankAccountTitle={setBankAccountTitle}
                    bankName={bankName}
                    setBankName={setBankName}
                    bankAccountBranch={bankAccountBranch}
                    setBankAccountBranch={setBankAccountBranch}
                    interestRate={interestRate}
                    setInterestRate={setInterestRate}
                    annualPayment={annualPayment}
                    setAnnualPayment={setAnnualPayment}
                    dailyExpense={dailyExpense}
                    setDailyExpense={setDailyExpense}
                    halfYearlyFee={halfYearlyFee}
                    setHalfYearlyFee={setHalfYearlyFee}
                    quarterlyFee={quarterlyFee}
                    setQuarterlyFee={setQuarterlyFee}
                    yearlyFee={yearlyFee}
                    setYearlyFee={setYearlyFee}
                    debitBankProfitLedger={debitBankProfitLedger}
                    setDebitBankProfitLedger={setDebitBankProfitLedger}
                    creditBankProfitLedger={creditBankProfitLedger}
                    setCreditBankProfitLedger={setCreditBankProfitLedger}
                    associatedLedgerExpense={associatedLedgerExpense}
                    setAssociatedLedgerExpense={setAssociatedLedgerExpense}
                    bankAccountFund={bankAccountFund}
                    setBankAccountFund={setBankAccountFund}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                  />
                </>
              );
            })}
          </>
        ) : item.child_family ? (
          item.child_family.map((item, index) => {
            return (
              <>
                <RowComponent
                  gap={gaping}
                  item={item}
                  statusModal={statusModal}
                  setStatusModal={setStatusModal}
                  changeStatusLedgerCode={changeStatusLedgerCode}
                  setChangeStatusLedgerCode={setChangeStatusLedgerCode}
                  editLedgerCode={editLedgerCode}
                  setEditLedgerCode={setEditLedgerCode}
                  plusIcon={plusIcon}
                  viewAddModal={setViewAddModal}
                  setViewAddModal={setViewAddModal}
                  addModalParentLedgerCode={addModalParentLedgerCode}
                  setAddModalParentLedgerCode={setAddModalParentLedgerCode}
                  addModalParentLedgerName={addModalParentLedgerName}
                  setAddModalParentLedgerName={setAddModalParentLedgerName}
                  setBankAccountCardExpanded={setBankAccountCardExpanded}
                  setExpenseCardExpanded={setExpenseCardExpanded}
                  setBankProfitCardExpanded={setBankProfitCardExpanded}
                  setFeeSlabsCardExpanded={setFeeSlabsCardExpanded}
                  parentName={parentName}
                  mainFamilyCode={mainFamilyCode}
                  setMainFamilyCode={setMainFamilyCode}
                  mainFamilyName={mainFamilyName}
                  setMainFamilyName={setMainFamilyName}
                  familyCode={familyCode}
                  setFamilyCode={setFamilyCode}
                  familyName={familyName}
                  setFamilyName={setFamilyName}
                  subFamilyCode={subFamilyCode}
                  setSubFamilyCode={setSubFamilyCode}
                  subFamilyName={subFamilyName}
                  setSubFamilyName={setSubFamilyName}
                  childFamilyCode={childFamilyCode}
                  setChildFamilyCode={setChildFamilyCode}
                  childFamilyName={childFamilyName}
                  setChildFamilyName={setChildFamilyName}
                  mainFamilyFlag={mainFamilyFlag}
                  setMainFamilyFlag={setMainFamilyFlag}
                  familyFlag={familyFlag}
                  setFamilyFlag={setFamilyFlag}
                  subFamilyFlag={subFamilyFlag}
                  setSubFamilyFlag={setSubFamilyFlag}
                  childFamilyFlag={childFamilyFlag}
                  setChildFamilyFlag={setChildFamilyFlag}
                  glFlag={glFlag}
                  setGlFlag={setGlFlag}
                  viewEditModal={viewEditModal}
                  setViewEditModal={setViewEditModal}
                  setViewOnly={setViewOnly}
                  glCode={glCode}
                  setGlCode={setGlCode}
                  glName={glName}
                  setGlName={setGlName}
                  currentBalance={currentBalance}
                  setCurrentBalance={setCurrentBalance}
                  associatedAccounts={associatedAccounts}
                  setAssociatedAccounts={setAssociatedAccounts}
                  relatedLedger={relatedLedger}
                  setRelatedLedger={setRelatedLedger}
                  slabData={slabData}
                  setSlabData={setSlabData}
                  fundsData={fundsData}
                  setFundsData={setFundsData}
                  bankAccountNumber={bankAccountNumber}
                  setBankAccountNumber={setBankAccountNumber}
                  bankAccountTitle={bankAccountTitle}
                  setBankAccountTitle={setBankAccountTitle}
                  bankName={bankName}
                  setBankName={setBankName}
                  bankAccountBranch={bankAccountBranch}
                  setBankAccountBranch={setBankAccountBranch}
                  interestRate={interestRate}
                  setInterestRate={setInterestRate}
                  annualPayment={annualPayment}
                  setAnnualPayment={setAnnualPayment}
                  dailyExpense={dailyExpense}
                  setDailyExpense={setDailyExpense}
                  halfYearlyFee={halfYearlyFee}
                  setHalfYearlyFee={setHalfYearlyFee}
                  quarterlyFee={quarterlyFee}
                  setQuarterlyFee={setQuarterlyFee}
                  yearlyFee={yearlyFee}
                  setYearlyFee={setYearlyFee}
                  debitBankProfitLedger={debitBankProfitLedger}
                  setDebitBankProfitLedger={setDebitBankProfitLedger}
                  creditBankProfitLedger={creditBankProfitLedger}
                  setCreditBankProfitLedger={setCreditBankProfitLedger}
                  associatedLedgerExpense={associatedLedgerExpense}
                  setAssociatedLedgerExpense={setAssociatedLedgerExpense}
                  bankAccountFund={bankAccountFund}
                  setBankAccountFund={setBankAccountFund}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              </>
            );
          })
        ) : item.gl ? (
          item.gl.map((item, index) => {
            return (
              <>
                <RowComponent
                  gap={gaping}
                  item={item}
                  statusModal={statusModal}
                  setStatusModal={setStatusModal}
                  changeStatusLedgerCode={changeStatusLedgerCode}
                  setChangeStatusLedgerCode={setChangeStatusLedgerCode}
                  editLedgerCode={editLedgerCode}
                  setEditLedgerCode={setEditLedgerCode}
                  plusIcon={false}
                  viewAddModal={setViewAddModal}
                  setViewAddModal={setViewAddModal}
                  addModalParentLedgerCode={addModalParentLedgerCode}
                  setAddModalParentLedgerCode={setAddModalParentLedgerCode}
                  addModalParentLedgerName={addModalParentLedgerName}
                  setAddModalParentLedgerName={setAddModalParentLedgerName}
                  setBankAccountCardExpanded={setBankAccountCardExpanded}
                  setExpenseCardExpanded={setExpenseCardExpanded}
                  setBankProfitCardExpanded={setBankProfitCardExpanded}
                  setFeeSlabsCardExpanded={setFeeSlabsCardExpanded}
                  parentName={parentName}
                  mainFamilyCode={mainFamilyCode}
                  setMainFamilyCode={setMainFamilyCode}
                  mainFamilyName={mainFamilyName}
                  setMainFamilyName={setMainFamilyName}
                  familyCode={familyCode}
                  setFamilyCode={setFamilyCode}
                  familyName={familyName}
                  setFamilyName={setFamilyName}
                  subFamilyCode={subFamilyCode}
                  setSubFamilyCode={setSubFamilyCode}
                  subFamilyName={subFamilyName}
                  setSubFamilyName={setSubFamilyName}
                  childFamilyCode={childFamilyCode}
                  setChildFamilyCode={setChildFamilyCode}
                  childFamilyName={childFamilyName}
                  setChildFamilyName={setChildFamilyName}
                  mainFamilyFlag={mainFamilyFlag}
                  setMainFamilyFlag={setMainFamilyFlag}
                  familyFlag={familyFlag}
                  setFamilyFlag={setFamilyFlag}
                  subFamilyFlag={subFamilyFlag}
                  setSubFamilyFlag={setSubFamilyFlag}
                  childFamilyFlag={childFamilyFlag}
                  setChildFamilyFlag={setChildFamilyFlag}
                  glFlag={glFlag}
                  setGlFlag={setGlFlag}
                  viewEditModal={viewEditModal}
                  setViewEditModal={setViewEditModal}
                  setViewOnly={setViewOnly}
                  glCode={glCode}
                  setGlCode={setGlCode}
                  glName={glName}
                  setGlName={setGlName}
                  currentBalance={currentBalance}
                  setCurrentBalance={setCurrentBalance}
                  associatedAccounts={associatedAccounts}
                  setAssociatedAccounts={setAssociatedAccounts}
                  relatedLedger={relatedLedger}
                  setRelatedLedger={setRelatedLedger}
                  slabData={slabData}
                  setSlabData={setSlabData}
                  fundsData={fundsData}
                  setFundsData={setFundsData}
                  bankAccountNumber={bankAccountNumber}
                  setBankAccountNumber={setBankAccountNumber}
                  bankAccountTitle={bankAccountTitle}
                  setBankAccountTitle={setBankAccountTitle}
                  bankName={bankName}
                  setBankName={setBankName}
                  bankAccountBranch={bankAccountBranch}
                  setBankAccountBranch={setBankAccountBranch}
                  interestRate={interestRate}
                  setInterestRate={setInterestRate}
                  annualPayment={annualPayment}
                  setAnnualPayment={setAnnualPayment}
                  dailyExpense={dailyExpense}
                  setDailyExpense={setDailyExpense}
                  halfYearlyFee={halfYearlyFee}
                  setHalfYearlyFee={setHalfYearlyFee}
                  quarterlyFee={quarterlyFee}
                  setQuarterlyFee={setQuarterlyFee}
                  yearlyFee={yearlyFee}
                  setYearlyFee={setYearlyFee}
                  debitBankProfitLedger={debitBankProfitLedger}
                  setDebitBankProfitLedger={setDebitBankProfitLedger}
                  creditBankProfitLedger={creditBankProfitLedger}
                  setCreditBankProfitLedger={setCreditBankProfitLedger}
                  associatedLedgerExpense={associatedLedgerExpense}
                  setAssociatedLedgerExpense={setAssociatedLedgerExpense}
                  bankAccountFund={bankAccountFund}
                  setBankAccountFund={setBankAccountFund}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              </>
            );
          })
        ) : null
      ) : null}
    </>
  );
};

export default RowComponent;
