import axios from "axios";
import Config from "../../config/index";
import RefreshTokenHandler from "./refresh-token";

/**
 * @param email
 */
const getNatureOfTx = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/not?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getNatureOfTx(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
/**
 * @param email
 */
const getModeOfTx = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/tot?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getModeOfTx(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};
/**
 * @param email
 */
const getModeOfPayments = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/mop?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getModeOfPayments(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 * @param email
 */
const getTxnCatagory = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/txn-catagory?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getTxnCatagory(email);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 */
const getAllPendingTransactions = async (
  email: string,
  status: string,
  search_value: string,
  created_by: string = "",
  page_number: string = "",
  txnr_type: string = "",
  fund_code: string = "",
  amc_code: string = ""
) => {
  const url = `${Config.baseUrl}/transaction?email=${email}&status=${status}&search_value=${search_value}&created-by=${created_by}&page_number=${page_number}&page_size=10&txnr_type=${txnr_type}&fund_code=${fund_code}&amc_code=${amc_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllPendingTransactions(
          email,
          status,
          search_value,
          (created_by = ""),
          (page_number = ""),
          txnr_type,
          fund_code,
          amc_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const updateTransactionStatus = async (
  email: string,
  status: string,
  trx_id: string,
  note: string,
  check_list: any
) => {
  const url = `${Config.baseUrl}/transaction/update-status`;
  try {
    const result: any = await axios.put(
      url,
      {
        email,
        status,
        trx_id,
        note,
        check_list,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateTransactionStatus(
          email,
          status,
          trx_id,
          note,
          check_list
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

// add Equity
const addEquityTransaction = async (
  email: string,
  fund: string,
  account_no: string,
  name_of_beneficiary: string,
  beneficiary_bank: string,
  beneficiary_branch: string,
  beneficiary_account: string,
  instrument_date: string,
  symbol: string,
  instrument_no: string,
  instrument_type: string,
  type_of_transaction: string,
  trade_type: string,
  brokage: string,
  gross_amount: string,
  fed: string,
  trade_date: string,
  sst: string,
  commission: string,
  settlement_date: string,
  net_amount: string,
  broker_code: string,
  // broker_name: string,
  volume: string,
  avg_rate: string,
  amc_name: string,
  trx_id: string,
  broker_name: string,
  txn_status?: string,
  note?: string,
  check_list?: any,
  updated_by_role?: string
) => {
  let type_of_security = symbol;
  const url = `${Config.baseUrl}/transaction/equity-settlement/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        fund,
        account_no,
        name_of_beneficiary,
        beneficiary_bank,
        beneficiary_branch,
        beneficiary_account,
        instrument_date,
        symbol,
        type_of_security,
        instrument_no,
        instrument_type,
        type_of_transaction,
        trade_type,
        brokage,
        gross_amount,
        fed,
        trade_date,
        sst,
        commission,
        settlement_date,
        net_amount,
        broker_code,
        volume,
        avg_rate,
        amc_name,
        trx_id,
        broker_name,
        txn_status,
        note,
        check_list,
        updated_by_role,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addEquityTransaction(
          email,
          fund,
          account_no,
          name_of_beneficiary,
          beneficiary_bank,
          beneficiary_branch,
          beneficiary_account,
          instrument_date,
          symbol,
          instrument_no,
          instrument_type,
          type_of_transaction,
          trade_type,
          brokage,
          gross_amount,
          fed,
          trade_date,
          sst,
          commission,
          settlement_date,
          net_amount,
          broker_code,
          volume,
          avg_rate,
          amc_name,
          trx_id,
          broker_name,
          txn_status,
          note,
          check_list,
          updated_by_role
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

//display type
const getCaptype = async () => {
  const url = `${Config.baseUrl}/transaction/txntypes`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCaptype();
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getCoListing = async (email: string, amcCode: string) => {
  const url = `${Config.baseUrl}/amc/members-by-code?email=${email}&amc_code=${amcCode}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCoListing(email, amcCode);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getAllTransactions = async (
  email: string,
  status: string,
  search_value: string,
  created_by: string = "",
  page_number: string = "",
  create_email: string = ""
) => {
  const url = `${Config.baseUrl}/transaction?email=${email}&status=${status}&search_value=${search_value}&created-by=${created_by}&page_number=${page_number}&page_size=10&create_email=${create_email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllTransactions(
          email,
          status,
          search_value,
          (created_by = ""),
          (page_number = ""),
          (create_email = "")
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

/**
 *
 * @param email
 */
const getAllAmcTransactions = async (
  email: string,
  amc_name,
  status: string,
  search_value: string,
  created_by: string = "",
  page_number: string = ""
) => {
  const url = `${Config.baseUrl}/transaction/amc-txns?email=${email}&status=${status}&search_value=${search_value}&created-by=${created_by}&page_number=${page_number}&page_size=10&amc_code=${amc_name}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllAmcTransactions(
          email,
          amc_name,
          status,
          search_value,
          (created_by = ""),
          (page_number = "")
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const updateBulkTransactionStatus = async (
  email: string,
  role: string,
  status: string,
  note: string,
  check_list: any,
  data: any
) => {
  const url = `${Config.baseUrl}/transaction/bulk-update`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        role,
        status,
        note,
        check_list,
        data,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || "",
        },
      }
    );
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateBulkTransactionStatus(
          email,
          role,
          status,
          note,
          check_list,
          data
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getLedger = async (email: string, txn_id: string) => {
  const url = `${Config.baseUrl}/transaction/get-ledger-by-txnid?email=${email}&txn_id=${txn_id}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getLedger(email, txn_id);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const searchTransactions = async (
  email: string,
  amc_code: string,
  fund_code: string,
  status: string,
  search_value: string,
  page_number: string,
  txnr_type: string = ""
) => {
  const url = `${Config.baseUrl}/transaction/get-txn-filter?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&txn_status=${status}&txn_id=${search_value}&page_number=${page_number}&page_size=10&txnr_type=${txnr_type}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await searchTransactions(
          email,
          amc_code,
          fund_code,
          status,
          search_value,
          page_number,
          txnr_type
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getCorporateAnnouncemetTxns = async (
  email: string,
  amc_code: string,
  page_number: string = ""
) => {
  // const url = `${Config.baseUrl}/get-corporate-announcements?email=${email}&status=${status}&search_value=${search_value}&created-by=${created_by}&page_number=${page_number}&page_size=10`;
  const url = `${Config.baseUrl}/transaction/get-corporate-announcements?email=${email}&amc_code=${amc_code}&page_number=${page_number}&page_size=10`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCorporateAnnouncemetTxns(email, amc_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getPreviousTransactionsCGTCalculation = async (
  email: string,
  amc_code: string,
  fund_code: string,
  folio_number: string,
  redemption_date: string
) => {
  const url = `${Config.baseUrl}/transaction/get-completed-txn-filter?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&folio_number=${folio_number}&redemption_date=${redemption_date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPreviousTransactionsCGTCalculation(
          email,
          amc_code,
          fund_code,
          folio_number,
          redemption_date
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export {
  getAllAmcTransactions,
  getCoListing,
  getAllTransactions,
  getTxnCatagory,
  getCaptype,
  updateTransactionStatus,
  getNatureOfTx,
  addEquityTransaction,
  getModeOfTx,
  getModeOfPayments,
  getAllPendingTransactions,
  updateBulkTransactionStatus,
  getLedger,
  searchTransactions,
  getCorporateAnnouncemetTxns,
  getPreviousTransactionsCGTCalculation,
};
