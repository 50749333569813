import React, { useState, useContext, useEffect } from "react";
import { getAllBanks } from "../../../stores/services/bank.service";
import { getAmc } from "../../../stores/services/amc.service";
import { getBranchByBankName } from "../../../stores/services/branch.service";
import { getFunds } from "../../../stores/services/funds.service";
import { addAccount } from "../../../stores/services/account.service";
import { getFundByAmc } from "../../../stores/services/funds.service";
import ReactTooltip from "react-tooltip";
import { Container, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";
import {
  accountTitle_AccountNumber_Regex,
  getAllLedgers,
} from "../../../utils/customFunction";
import NumberFormat from "react-number-format";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { useSelector, useDispatch } from "react-redux";
import { replaceChartOfAccountsFile } from "../../../stores/services/chatofaccounts.service";
import { GetChartOfAccount } from "../../../redux/actions/chartOfAccount-Actions";

const SetupAccount = () => {
  const history = useHistory();
  const context = useContext(ThemeContext);
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const dispatch = useDispatch();
  //hooks for getting all inputs from user
  const amcCode = sessionStorage.getItem("amc_code") || "";
  const [bankname, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [amcName, setAmcName] = useState(amcCode);
  const [city, setCity] = useState("");
  const [fund, setFund] = useState("");
  const [currency, setCurrency] = useState("PKR");
  const [txNature, setTxNature] = useState("");
  const [iban, setIban] = useState("");
  const [accType, setAccType] = useState("");
  const [accPurpose, setAccPurpose] = useState("");
  const [amount, setAmount] = useState("0");
  const [headEmail, setHeadEmail] = useState("");
  const [headPhone, setHeadPhone] = useState("");
  const [branchHeadEmail, setBranchHeadEmail] = useState("");
  const [branchHeadMobile, setBranchHeadMobile] = useState("");
  const [folioNo, setFolioNo] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceError, setCurrentBalanceError] =
    useState<boolean>(false);

  const [interestRate, setInterestRate] = useState<any>("");
  const [debitBankProfitLedger, setDebitBankProfitLedger] = useState<any>();
  const [creditBankProfitLedger, setCreditBankProfitLedger] = useState<any>();
  const [associatedLedgerExpense, setAssociatedLedgerExpense] = useState<any>();
  const [interestRateError, setInterestRateError] = useState<boolean>(false);
  const [debitBankProfitLedgerError, setDebitBankProfitLedgerError] =
    useState<boolean>(false);
  const [creditBankProfitLedgerError, setCreditBankProfitLedgerError] =
    useState<boolean>(false);
  const [associatedLedgerExpenseError, setAssociatedLedgerExpenseError] =
    useState<boolean>(false);

  const [ledgerOption, setLedgerOption] = useState<any>([]);
  const [allLedgerData, setAllLedgerData] = useState<any>([]);

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);

  useEffect(() => {
    if (chartOfAccounts?.length > 0) {
      setLedgersData();
    }
  }, [chartOfAccounts]);

  const setLedgersData = async () => {
    setAllLedgerData(await getAllLedgers(chartOfAccounts));
  };

  const email = sessionStorage.getItem("email") || "";
  const [fundLoading, setFundLoading] = useState<boolean>(false);
  const [smaCheck, setSmaCheck] = useState<boolean>(false);

  useEffect(() => {
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.name}`,
              value: item.code,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.bank}`,
              value: item.code,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
            value: item.code,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
            value: item.code,
          },
        ]);
      }
    });
  }, [allLedgerData]);

  // const getfundByAmcCode = async (amcName: string) => {
  //   setFundLoading(true);
  //   // allFunds.length = 0;
  //   // setAllFunds(allFunds);
  //   //get funds by amc for dropdown
  //   try {
  //     const response = await getFundByAmc(email, amcName);
  //     setAllFunds(response.data.data);
  //   } catch (error) {}
  //   setFundLoading(false);
  // };
  //error getting hooks

  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [amcNameError, setAmcError] = useState("");
  const [fundError, setFundError] = useState("");
  const [currencyError, setCurrencyError] = useState("");
  const [txNatureError, setTxNatureError] = useState("");
  const [ibanError, setIbanError] = useState("");
  const [accTypeError, setAccTypeError] = useState("");
  const [accPurposeError, setAccPurposeError] = useState("");
  const [headEmailError, setHeadEmailError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [folioNoError, setFolioNoError] = useState("");
  const [cityError, setCityError] = useState("");

  const [Loading, setLoading] = useState(false);
  //for dropdown data
  const [amcdata, setAmcdata] = useState<any>([]);
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);

  const [headPhoneError, setHeadPhoneError] = useState("");
  const [branchHeadEmailError, setBranchHeadEmailError] = useState("");
  const [branchHeadMobileError, setBranchHeadMobileError] = useState("");
  const getBranchByName = async (name: string) => {
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name, amcCode);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    const fetchAmc = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all Amc for dropdown
      // try {
      //   const amcResponse = await getAmc(email);
      //   setAmcdata(amcResponse.data.data);
      // } catch (error) {}

      // get funds by amcCode
      setFundLoading(true);
      // allFunds.length = 0;
      // setAllFunds(allFunds);
      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          amcName,
          fund_code?.length > 0 ? fund_code : ""
        );
        setAllFunds(response.data.data);
      } catch (error) {}
      setFundLoading(false);

      //get all banks list for dropdown
      try {
        const response = await getAllBanks(email, "", "", amcCode);
        setBankNameData(response.data.data);
      } catch (error) {}
      //get tx nature data for dropdown
    };
    fetchAmc();
  }, []);
  //render dropdown for amc data
  // const renderAmcDropdown = () => {
  //   return amcdata.map((item: any, index: string) => {
  //     return (
  //       <option key={index} value={item.amc_code.replace('AMC_','')}>
  //         {item.name} ({item.amc_code.replace('AMC_','')})
  //       </option>
  //     );
  //   });
  // };
  //render dropdown for bank name data
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace("BANK_", "")}>
          {item.bank_name} ({item.bank_code.replace("BANK_", "")})
        </option>
      );
    });
  };
  //render dropdown for branch name data
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol_code}>
          {` ${item.fund_name} (${item.symbol_code})`}
        </option>
      );
    });
  };
  //render dropdown for nature of transaction data
  const validate = () => {
    let bankErr,
      branchErr,
      amcErr,
      fundErr,
      currencyErr = "";
    let txErr,
      ibanErr,
      accErr,
      accPurErr,
      amountErr,
      headEmailErr,
      headPhoneErr,
      branchHEmailerr,
      cityErr,
      interestRateErr,
      debitBankProfitLedgerErr,
      creditBankProfitLedgerErr,
      BranchHPhoneErr = "";
    bankname.trim() === "" ? (bankErr = "Required") : (bankErr = "");
    branchName.trim() === "" ? (branchErr = "Required") : (branchErr = "");
    amcName.trim() === "" ? (amcErr = "Required") : (amcErr = "");
    fund.trim() === "" ? (fundErr = "Required") : (fundErr = "");
    // currency.trim() === '' ? currencyErr = "Required" : currencyErr = "";
    txNature.trim() === "" ? (txErr = "Required") : (txErr = "");
    iban.trim() === "" ? (ibanErr = "Required") : (ibanErr = "");
    accType.trim() === "" ? (accErr = "Required") : (accErr = "");
    city.trim() === "" ? (cityErr = "Required") : (cityErr = "");
    interestRate.trim() === ""
      ? (interestRateErr = "required-border")
      : (interestRateErr = "");
    !debitBankProfitLedger?.value
      ? (debitBankProfitLedgerErr = "required-border")
      : (debitBankProfitLedgerErr = "");
    !creditBankProfitLedger?.value
      ? (creditBankProfitLedgerErr = "required-border")
      : (creditBankProfitLedgerErr = "");
    // amount.trim() === '' ? amountErr = "Required" : amountErr = '';
    // headEmail.trim() === '' ? headEmailErr = "Required" : headEmailErr = '';

    // headPhone.trim() === '' ? headPhoneErr = "Required" : headPhoneErr = '';
    // branchHeadEmail.trim() === '' ? branchHEmailerr = "Required" : branchHEmailerr = '';
    // branchHeadMobile.trim() === '' ? BranchHPhoneErr = "Required" : BranchHPhoneErr = '';

    if (
      bankErr ||
      branchErr ||
      amcErr ||
      headPhoneErr ||
      branchHEmailerr ||
      BranchHPhoneErr ||
      fundErr ||
      ibanErr ||
      currencyErr ||
      txErr ||
      accErr ||
      accPurErr ||
      amountErr ||
      cityErr ||
      interestRateErr ||
      debitBankProfitLedgerErr ||
      creditBankProfitLedgerErr
    ) {
      setBankNameError(bankErr);
      setCityError(cityErr);
      //setHeadPhoneError(headPhoneErr);
      // setBranchHeadMobileError(BranchHPhoneErr);
      // setBranchHeadEmailError(branchHEmailerr);
      setBranchNameError(branchErr);
      setAmcError(amcErr);
      setFundError(fundErr);
      setCurrencyError(currencyErr);
      setTxNatureError(txErr);
      setAccTypeError(accErr);
      setIbanError(ibanErr);
      setAccPurposeError(accPurErr);
      setAmountError(amountErr);
      setHeadEmailError(headEmailErr);
      setInterestRateError(interestRateErr);
      setDebitBankProfitLedgerError(debitBankProfitLedgerErr);
      setCreditBankProfitLedgerError(creditBankProfitLedgerErr);
      return false;
    } else {
      return true;
    }
  };
  const addAccountProcess = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        let temp = [...chartOfAccounts[0].family[3].sub_family];
        let gl_code;
        // if(txNature == "Current"){
        let currentAccounts = temp.find(
          (item) =>
            item.name ==
            (txNature == "Current" ? "CURRENT ACCOUNTS" : "PLS ACCOUNTS")
        );
        gl_code =
          currentAccounts?.gl?.length > 0
            ? currentAccounts?.gl[currentAccounts?.gl.length - 1]?.code + 1
            : parseInt(`${currentAccounts.code}01`);
        currentAccounts.gl.push({
          account_no: iban,
          account_title: accType,
          active: true,
          balance: currentBalance?.toString() || "0",
          bank: bankname,
          branch: branchName,
          code: gl_code,
          interest_rate: interestRate?.toString() || "0",
          related_ledgers: [
            parseInt(debitBankProfitLedger?.value),
            parseInt(creditBankProfitLedger?.value),
          ],
        });
        temp.find(
          (item) =>
            item.name ==
            (txNature == "Current" ? "CURRENT ACCOUNTS" : "PLS ACCOUNTS")
        ).gl = currentAccounts.gl;

        // }else if (txNature == "PLS"){
        //   let PLSAccounts = temp.find(item => item.name == "PLS ACCOUNTS");
        //   gl_code = PLSAccounts?.gl?.length > 0 ? PLSAccounts?.gl[PLSAccounts?.gl.length -1].code + 1 : parseInt(`${PLSAccounts.code}01`) ;
        //   PLSAccounts.gl.push({
        //     account_no:iban,
        //     account_title:accType,
        //     active:true,
        //     balance:currentBalance?.toString() || "0",
        //     bank:bankname,
        //     branch:branchName,
        //     code: gl_code,
        //     interest_rate: interestRate?.toString() || "0",
        //     related_ledgers:[parseInt(debitBankProfitLedger?.value),
        //       parseInt(creditBankProfitLedger?.value)]
        //   })
        //   temp.find(item => item.name == "PLS ACCOUNTS").gl = PLSAccounts.gl;

        // }
        const response = await addAccount(
          email,
          amcCode,
          bankname,
          branchName,
          iban,
          fund,
          accType,
          txNature,
          currency,
          // amount,
          currentBalance,
          headEmail,
          smaCheck,
          headPhone,
          branchHeadEmail,
          branchHeadMobile,
          folioNo,
          city,
          gl_code
        );
        if (response.data.status === 200) {
          let completeChartsofAccounts = [...chartOfAccounts];
          completeChartsofAccounts[0].family[3].sub_family = temp;
          await replaceChartOfAccountsFile(
            email,
            JSON.stringify({ main_family: completeChartsofAccounts }),
            amcCode
          );

          dispatch(GetChartOfAccount(completeChartsofAccounts));
          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/bank-accounts`);
          }, 2000);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message[0]);
        } else {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error("Please Fill All Required Fields");
    }
  };

  const [codeChange, setCodeChange] = useState("");

  const decimalValue = (value, len) => {
    let startingValue = value.split(".")[0];
    let decimalVal = value.split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="input-holder">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-1 pointer"
                    onClick={() => {
                      history.replace("/admin/bank-accounts");
                    }}
                  ></i>
                  Setup - Bank Account
                </h4>
                <Link
                  to="/admin/bank-accounts"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i>View All
                </Link>
              </div>

              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Fund Detail</h4>
                    </div>
                    <div className="card-body">
                      {/* <div className="form-group">	
                    <label>AMC Name <span className="color-amber">*</span></label>	
                    <select	
                      className={`form-control  w-100 ${amcNameError ? 'required-border' : ''	
                        }`}	
                      defaultValue={amcName}	
                      onChange={(e) => {	
                        setAmcName(e.target.value);	
                        setAmcError('');	
                        getfundByAmcCode(e.target.value);	
                      }}	
                    >	
                      <option value="" defaultChecked hidden>	
                        {' '}	
                            Select An AMC	
                          </option>	
                      {renderAmcDropdown()}	
                    </select>	
                  </div> */}

                      <div className="form-group">
                        <label>
                          Fund Selection <span className="color-amber">*</span>
                        </label>
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <div className="" data-tip="First Select Amc">
                          {fundLoading ? (
                            <div className="form-group">
                              <div className="ml-2 form-control">
                                Fund Loading
                                <i
                                  className="fa fa-spinner fa-spin fa-1x ml-2"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          ) : (
                            <select
                              className={`form-control  w-100 ${
                                fundError ? "required-border" : ""
                              }`}
                              value={fund}
                              onChange={(e) => {
                                setFundError("");
                                setFund(e.target.value);
                              }}
                            >
                              <option value="" defaultChecked hidden>
                                {" "}
                                Select Fund
                              </option>
                              {renderFundsDropdown()}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Bank Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label>
                          Bank Name <span className="color-amber">*</span>
                        </label>
                        <select
                          className={`form-control  w-100 ${
                            bankNameError ? "required-border" : ""
                          }`}
                          value={bankname}
                          onChange={(e) => {
                            setBankNameError("");
                            setBankName(e.target.value);
                            getBranchByName(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {" "}
                            Select Bank
                          </option>
                          {renderBankNameDropdown()}
                        </select>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Branch Name <span className="color-amber">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                branchNameError ? "required-border" : ""
                              }`}
                              value={branchName}
                              onChange={(e) => {
                                setBranchNameError("");
                                setBranchName(e.target.value);
                              }}
                            />
                            {/* <select	
                    className={`form-control  w-100 ${branchNameError ? 'required-border' : ''	
                      }`}	
                    value={branchName}	
                    onChange={(e) => {	
                      setBankNameError('');	
                      setBranchName(e.target.value);	
                    }}	
                  >	
                    <option value="" defaultChecked hidden>	
                      {branchCheckFlag	
                        ? 'No Branch Found'	
                        : 'Select Branch Name'}	
                    </option>	
                    {renderBranchsDropdown()}	
                  </select>	 */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              City <span className="color-amber">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                cityError ? " required-border" : ""
                              }`}
                              value={city}
                              onChange={(e) => {
                                setCityError("");
                                setCity(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Special Managed Account</h4>
                    </div>
                    <div className="card-body">
                      <FormGroup check className="mt-4">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={smaCheck}
                            onChange={(e) => {
                              if (smaCheck) {
                                setSmaCheck(false);
                                setFolioNo("");
                              } else {
                                setSmaCheck(true);
                              }
                            }}
                          />
                          <span className="form-check-sign" />
                          SMA (Optional)
                        </Label>
                      </FormGroup>

                      {smaCheck && (
                        <div className="form-group mt-4">
                          <label>Main Folio No</label>
                          <input
                            type="text"
                            placeholder="Folio No"
                            className={`form-control  w-100 ${
                              folioNoError ? "required-border" : ""
                            }`}
                            value={folioNo}
                            onChange={(e) => {
                              setFolioNo(e.target.value);
                              setFolioNoError("");
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              {/* Second Row */}
              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Contact Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label>Operation Manager Email </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Operation Manager Email"
                          value={headEmail}
                          onChange={(e) => {
                            setHeadEmail(e.target.value);
                            setHeadEmailError("");
                          }}
                        />
                        {/* {headEmailError ? (	
                    <p className="error-labels error-message2">	
                      {headEmailError}	
                    </p>	
                  ) : (	
                    ''	
                  )}	 */}
                      </div>

                      <div className="form-group">
                        <label>Operation Manager Phone </label>
                        {/* <input	
                    type="text"	
                    placeholder="Operation Manager Phone"	
                    className={`form-control  w-100 ${headPhoneError ? 'required-border' : ''	
                      }`}	
                    value={headPhone}	
                    onChange={(e) => {	
                      setHeadPhone(e.target.value);	
                      setHeadPhoneError('');	
                    }}	
                  />	 */}
                        <PhoneInput
                          specialLabel=""
                          inputClass={`form-control  w-100 ${
                            headPhoneError ? "" : ""
                          }`}
                          country={"pk"}
                          placeholder="+92 123-1234567"
                          value={headPhone}
                          onChange={(e) => {
                            setHeadPhone(e);
                            setHeadPhoneError("");
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>Branch Manager Email </label>
                        <input
                          type="text"
                          placeholder="Branch Manager Email"
                          className={`form-control  w-100 ${
                            branchHeadEmailError ? "" : ""
                          }`}
                          value={branchHeadEmail}
                          onChange={(e) => {
                            setBranchHeadEmail(e.target.value);
                            setBranchHeadEmailError("");
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>Branch Manager Phone </label>
                        {/*  <span className="color-amber">*</span> */}
                        {/* <input	
                    type="number"	
                    placeholder="Branch Manager Phone"	
                    className={`form-control  w-100 ${branchHeadMobileError ? 'required-border' : ''	
                      }`}	
                    value={branchHeadMobile}	
                    onChange={(e) => {	
                      setBranchHeadMobile(e.target.value);	
                      setBranchHeadMobileError('');	
                    }}	
                  />	 */}
                        <PhoneInput
                          specialLabel=""
                          inputClass={`form-control  w-100 ${
                            branchHeadMobileError ? "" : ""
                          }`}
                          country={"pk"}
                          placeholder="+92 123-1234567"
                          value={branchHeadMobile}
                          onChange={(e) => {
                            setBranchHeadMobile(e);
                            setBranchHeadMobileError("");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Account Detail</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label>
                          Bank Account Title{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Bank Account Title"
                          className={`form-control  w-100 ${
                            accTypeError ? " required-border" : ""
                          }`}
                          value={accType}
                          onChange={(e) => {
                            if (
                              e.target.value.match(
                                accountTitle_AccountNumber_Regex
                              ) ||
                              e.target.value == ""
                            ) {
                              setAccType(e.target.value);
                              setAccTypeError("");
                            }
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          IBAN / Account No{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="IBAN / Account No"
                          className={`form-control  w-100 ${
                            ibanError ? "required-border" : ""
                          }`}
                          value={iban}
                          onChange={(e) => {
                            if (
                              e.target.value.match(
                                accountTitle_AccountNumber_Regex
                              ) ||
                              e.target.value == ""
                            ) {
                              setIban(e.target.value);
                              setIbanError("");
                            }
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Nature of Account{" "}
                          <span className="color-amber">*</span>
                        </label>
                        <select
                          className={`form-control  w-100 ${
                            txNatureError ? "required-border" : ""
                          }`}
                          value={txNature}
                          onChange={(e) => {
                            setTxNatureError("");
                            setTxNature(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {" "}
                            Select Nature
                          </option>
                          <option value="Current">Current</option>
                          {/* <option value="Saving">Saving</option>	 */}
                          <option value="PLS">PLS</option>
                          {/* <option value="Divident">Dividend</option>	
                    <option value="TDR">TDR</option> */}
                        </select>
                      </div>

                      <div className="form-group">
                        <label>Currency</label>
                        <select
                          className={`form-control  w-100 ${
                            currencyError ? "required-border" : ""
                          }`}
                          value={currency}
                          onChange={(e) => {
                            setCurrencyError("");
                            setCurrency(e.target.value);
                          }}
                        >
                          <option value="PKR" defaultChecked>
                            PKR
                          </option>
                          <option value="USD">USD</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>Current Balance</label>
                        <NumberFormat
                          thousandSeparator={true}
                          inputMode="numeric"
                          placeholder="Current Balance"
                          value={currentBalance}
                          className={`form-control w-100 `}
                          style={{ textAlign: "right" }}
                          onValueChange={(e) => {
                            setCurrentBalance(e.value);
                          }}
                        />
                      </div>

                      <div
                        className="form-group"
                        data-tip="Interest rate for Bank Profit"
                      >
                        <label>
                          Interest Rate % <span className="color-amber">*</span>
                        </label>
                        <input
                          type="number"
                          placeholder="Interest rate to calculate bank profit"
                          value={interestRate}
                          className={`form-control w-100 ${interestRateError}`}
                          style={{ textAlign: "right" }}
                          onChange={(e) => {
                            setInterestRateError(false);
                            setInterestRate(e.target.value);
                          }}
                        />
                      </div>

                      <div
                        className="form-group"
                        data-tip="Ledger Codes to Debit/Credit in case of bank profit"
                      >
                        <div>
                          Associated Ledger Codes{" "}
                          <span className="color-amber">*</span>
                        </div>

                        {ledgerOption.length > 1 ? (
                          <Autocomplete
                            className={`mt-2 autocomplete ${debitBankProfitLedgerError}`}
                            data-tip="Ledger Code to associate in case of Expense"
                            disablePortal
                            id="combo-box-demo"
                            options={ledgerOption}
                            style={{ width: "100%" }}
                            PaperComponent={({ children }) => (
                              <Paper
                                style={{
                                  background: context.theme ? "" : "#1E1E2F",
                                  color: "white",
                                }}
                              >
                                {children}
                              </Paper>
                            )}
                            sx={{
                              "& .MuiAutocomplete-input": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                              "& .MuiInputLabel-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },

                              "& .MuiIconButton-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                            }}
                            value={debitBankProfitLedger}
                            onChange={(event: any, newValue: string | null) => {
                              setDebitBankProfitLedger(newValue);
                              setDebitBankProfitLedgerError(false);
                            }}
                            inputValue={debitBankProfitLedger}
                            onInputChange={(event, newInputValue) => {
                              setDebitBankProfitLedgerError(false);
                              setDebitBankProfitLedger(newInputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Ledger for debit"
                                variant="standard"
                              />
                            )}
                          />
                        ) : (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        )}

                        {ledgerOption.length > 1 ? (
                          <Autocomplete
                            className={`mt-2 autocomplete ${creditBankProfitLedgerError}`}
                            data-tip="Ledger Code to associate in case of Expense"
                            disablePortal
                            id="combo-box-demo"
                            options={ledgerOption}
                            style={{ width: "100%" }}
                            PaperComponent={({ children }) => (
                              <Paper
                                style={{
                                  background: context.theme ? "" : "#1E1E2F",
                                  color: "white",
                                }}
                              >
                                {children}
                              </Paper>
                            )}
                            sx={{
                              "& .MuiAutocomplete-input": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                              "& .MuiInputLabel-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },

                              "& .MuiIconButton-root": {
                                color: context.theme
                                  ? "black !important"
                                  : "rgba(255, 255, 255, 0.5) !important",
                              },
                            }}
                            value={creditBankProfitLedger}
                            onChange={(event: any, newValue: string | null) => {
                              setCreditBankProfitLedger(newValue);
                              setCreditBankProfitLedgerError(false);
                            }}
                            inputValue={creditBankProfitLedger}
                            onInputChange={(event, newInputValue) => {
                              setCreditBankProfitLedgerError(false);
                              setCreditBankProfitLedger(newInputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Ledger for credit"
                                variant="standard"
                              />
                            )}
                          />
                        ) : (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        )}
                      </div>

                      {/* <div className="form-group">	
                  <label>Amount</label>	
                  <input	
                    type="number"	
                    placeholder="Amount"	
                    className={`form-control  w-100 ${amountError ? 'required-border' : ''	
                      }`}	
                    value={amount}	
                    onChange={(e: any) => {
                      let input = decimalValue(e.target.value, 2);
                      if (+input < 0) {
                        input = (+input * -1).toString();
                      }
                      setAmount(input);
                      setAmountError('');
                    }}	
                  />	
                </div> */}
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="form-group">
                <button
                  className="btn btn-primary "
                  onClick={addAccountProcess}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Create</span>
                  )}
                </button>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default SetupAccount;
